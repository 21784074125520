import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';


// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDnBC5Dw9k-gbnNPGrAvj3flBjUBY7b6fs",
  authDomain: "whatsappinitiative.firebaseapp.com",
  projectId: "whatsappinitiative",
  storageBucket: "whatsappinitiative.appspot.com",
  messagingSenderId: "628789528132",
  appId: "1:628789528132:web:b0eda1c047b22daae09e11",
  measurementId: "G-XEZD454ZRE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
// Function to sign up new users
export const signUp = (email, password, onSuccess, onError) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // User registered successfully
      // Assign default credits to the new user
      return setDoc(doc(db, "transcriptions", userCredential.user.uid), {
        credits: 3
      }).then(() => onSuccess && onSuccess(userCredential));
    })
    .catch((error) => {
      // Error handling
      onError && onError(error);
    });
};


// Function to sign in existing users
export const signIn = (email, password, onSuccess, onError) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // User signed in successfully
      onSuccess && onSuccess(userCredential);
    })
    .catch((error) => {
      // Error handling
      onError && onError(error);
    });
};

// Function for forgot password
export const forgotPassword = (email, onSuccess, onError) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      // Email sent successfully
      onSuccess && onSuccess();
    })
    .catch((error) => {
      // Error handling
      onError && onError(error);
    });
};

// The Auth component
const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [accountType, setAccountType] = useState('');
  const [tosAgreed, setTosAgreed] = useState(false);
  const [disclaimerAgreed, setDisclaimerAgreed] = useState(false);

  const handleSignUp = (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords don't match!");
      return;
    }
    signUp(email, password, 
      (userCredential) => {
        console.log("User registered:", userCredential.user);
        window.location.replace("/dashboard");
      },
      (error) => {
        console.error("Error:", error.message);
        alert(error.message);
      }
    );
  };

  const handleSignIn = (event) => {
    event.preventDefault();
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    signIn(email, password, 
      (userCredential) => {
        console.log("User signed in:", userCredential.user);
        // Additional logic after successful sign in
        window.location.replace("landing.html");
      },
      (error) => {
        console.error("Error signing in:", error.message);
        alert(error.message);
      }
    );
  };

  const handleForgotPassword = () => {
    const emailAddress = prompt('Please enter your email address:');
    if (emailAddress) {
      forgotPassword(emailAddress,
        () => {
          alert('Password reset email sent! Please check your inbox.');
        },
        (error) => {
          alert('Error: ' + error.message);
        }
      );
    }
  };

  return (
    <div>
      {/* Your authentication UI here */}
      <form id="loginForm" onSubmit={handleSignIn}>
        {/* Login fields */}
      </form>
      <form onSubmit={handleSignUp}>
        {/* Existing input fields */}
        <input type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
        <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="First Name" required />
        <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Last Name" required />
        <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Choose Password" required />
        <input type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} placeholder="Confirm Password" required />
        <input type="date" value={dateOfBirth} onChange={e => setDateOfBirth(e.target.value)} placeholder="Date of Birth" required />
        <select value={accountType} onChange={e => setAccountType(e.target.value)}>
          <option value="">Select Account Type</option>
          {/* Add options for account types */}
        </select>

        {/* Terms of Service and Disclaimer checkboxes */}
        <div className="auth-checkbox-container">
          <input 
            type="checkbox" 
            id="tosCheckbox" 
            checked={tosAgreed} 
            onChange={e => setTosAgreed(e.target.checked)} 
          />
          <label htmlFor="tosCheckbox">
            I agree to the <a href="/path-to-your-tos" target="_blank">Terms of Service</a>
          </label>
        </div>
        <div className="auth-checkbox-container">
          <input 
            type="checkbox" 
            id="disclaimerCheckbox" 
            checked={disclaimerAgreed} 
            onChange={e => setDisclaimerAgreed(e.target.checked)} 
          />
          <label htmlFor="disclaimerCheckbox">
            I agree to the <a href="/path-to-your-disclaimer" target="_blank">Disclaimer</a>
          </label>
        </div>

        <button type="submit">Register</button>
      </form>
      <button id="forgotPasswordButton" onClick={handleForgotPassword}>
        Forgot Password
      </button>
    </div>
  );
};



export default Auth;