import React, { useState, useEffect, useRef } from 'react';
import { 
  getAuth, 
  onAuthStateChanged, 
  signOut 
} from 'firebase/auth';
import { 
  getFirestore, 
  doc, 
  getDoc, 
  collection, 
  query, 
  getDocs,
  addDoc, 
  onSnapshot,
  updateDoc,
  setDoc,  // Add this import for setDoc
  orderBy,
  Timestamp,
  deleteDoc,
  serverTimestamp // Add this import for serverTimestamp
} from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable,uploadBytes, getDownloadURL } from 'firebase/storage';
import './transcription.css';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51Ow865DxAh0T82HaLcjhVMawKPRHW3yjNcOab352tansJpwBbv7veA2Vnr62TysPXqSoVBDyYNYTXVWEvQkpTs9k0024k2Qfat');
const TranscriptionDashboard = () => {
    const auth = getAuth();
    const db = getFirestore();
    const storage = getStorage();
    const [user, setUser] = useState(null);
    const [transcriptions, setTranscriptions] = useState([]);
    const [credits, setCredits] = useState(0);
    const [audioFile, setAudioFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTranscription, setSelectedTranscription] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [isAuthChecked, setIsAuthChecked] = useState(false); // New state variable
    const [isQuestionSent, setIsQuestionSent] = useState(false);
    const [chatHistories, setChatHistories] = useState({}); // This should be an object
    const [selectedTranscriptions, setSelectedTranscriptions] = useState([]); // This should be an array
    const [isMultiChatModalOpen, setIsMultiChatModalOpen] = useState(false);
    const [multiChatHistory, setMultiChatHistory] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const commonQuestions = [
      "What are the key takeaways?",
      "What action items were discussed?",
      "Who were the main speakers?",
      "Were any decisions made?",
      "Can you highlight any concerns raised?"
    ];
    const [youtubeLink, setYoutubeLink] = useState('');
    const chatHistoryEndRef = useRef(null);
    const [expandedSummaryId, setExpandedSummaryId] = useState(null);
    const [excelFile, setExcelFile] = useState(null); // State for Excel fileUpload
    const [excelQuestion, setExcelQuestion] = useState(''); // State for Excel question
    const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
    const [billingData, setBillingData] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    // Handle authentication state change
  useEffect(() => {
    
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        console.log("User is authenticated:", currentUser.email);
        setUser(currentUser);
        fetchTranscriptions(currentUser);
        fetchUserCredits(currentUser); // Fetch user credits
      } else {
        handleUnauthenticatedUser();
      }
    });
    
    return () => unsubscribe(); // Cleanup subscription
  }, [auth]);
  const toggleSummary = (id) => {
    setExpandedSummaryId(expandedSummaryId === id ? null : id);
  };
  const toggleBillingModal = async () => {
    setIsBillingModalOpen(!isBillingModalOpen);
    if (!isBillingModalOpen) {
      await fetchBillingData();
    }
  };
  const fetchBillingData = async () => {
    try {
      const userRefPath = `customers/${user.uid}/checkout_sessions`;
      const q = query(collection(db, userRefPath));
  
      console.log(`Accessing Firestore path: /${userRefPath}`);
  
      const querySnapshot = await getDocs(q);
      const transactionsData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        let formattedDate = '';
  
        // Check and format 'created' date
        if (data.created && typeof data.created.toDate === 'function') {
          // If 'created' is a Firestore Timestamp
          formattedDate = data.created.toDate().toLocaleString();
        } else {
          formattedDate = 'Date not available';
        }
  
        return {
          created: formattedDate,
          quantity: data.quantity || 'N/A',
          price: data.price || 'N/A'
        };
      });
  
      setBillingData(transactionsData);
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };
  const scrollToBottomOfChat = () => {
  chatHistoryEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const toggleMultiChatModal = () => {
    setIsMultiChatModalOpen(!isMultiChatModalOpen);
  };

  const toggleTranscriptionSelection = (transcriptionId) => {
    setSelectedTranscriptions((prevSelected) =>
      prevSelected.includes(transcriptionId)
        ? prevSelected.filter((id) => id !== transcriptionId)
        : [...prevSelected, transcriptionId]
    );
  };
  const buyCredits = async () => {
    const userId = auth.currentUser.uid;
    console.warn("Setting client_reference_id:", userId);

    try {
      const checkoutSessionsRef = collection(db, 'customers', userId, 'checkout_sessions');
      const docRef = await addDoc(checkoutSessionsRef, {
        price: 'price_1NtKDALDe7ye8vLSgWMtkkjH',
        quantity: 1,
        success_url: window.location.href,
        cancel_url: window.location.href,
        mode: 'payment',
        client_reference_id: userId,
      });

      onSnapshot(docRef, async (snapshot) => {
        const { sessionId } = snapshot.data();
        if (sessionId) {
          const stripe = await stripePromise;
          stripe.redirectToCheckout({ sessionId });
        }
      });
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  const handleYoutubeSubmit = async (event) => {
    event.preventDefault();
    if (!youtubeLink) {
        alert("Please enter a YouTube link.");
        return;
    }

    const endpoint = "https://us-central1-whatsappinitiative.cloudfunctions.net/transcription_fcn";
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ youtubeUrl: youtubeLink, userId: user.uid }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("YouTube video processed:", data);
        fetchTranscriptions(user); // Refresh the list of transcriptions
        setYoutubeLink(''); // Reset the input field
    } catch (error) {
        console.error("Error processing YouTube video:", error);
    }
  };

  const fetchTranscriptions = async (currentUser) => {
    const q = query(collection(db, "transcriptions", currentUser.uid, "entries"));
    const querySnapshot = await getDocs(q);
    const fetchedTranscriptions = [];
    querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const name = docData.fileName || docData.audioName;
        const dateTime = doc.id.split('_');
        const dateStr = `${dateTime[0].substring(0, 2)}/${dateTime[0].substring(2, 4)}/${dateTime[0].substring(4, 6)}`;
        const timeStr = `${dateTime[1].substring(0, 2)}:${dateTime[1].substring(2, 4)}`;
        fetchedTranscriptions.push({
            id: doc.id,
            ...docData,
            audioName: name,
            date: dateStr,
            time: timeStr
        });
    });
    setTranscriptions(fetchedTranscriptions);
};
  const getAnswer = async (question, transcription) => {
    // Define the Cloud Function endpoint
    const endpoint = "https://us-central1-whatsappinitiative.cloudfunctions.net/query";
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ question, transcription })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data.answer;
    } catch (error) {
      console.error("Error calling the Cloud Function:", error);
      throw error;
    }
  };
  
  const askQuestion = async (docId, question) => {
    console.log("askQuestion: Start");
    const transcriptionText = selectedTranscription ? selectedTranscription.transcription : '';
    // Check if the user has enough credits
    if (credits < 0.4) {
        alert("You don't have enough credits to ask a question.");
        return;
    }
    setIsQuestionSent(true); // Set to true when question is asked

    try {
        const answer = await getAnswer(question, transcriptionText);

        setChatHistory(prevHistory => [...prevHistory, { type: 'user', text: question }, { type: 'bot', text: answer }]);
        setIsQuestionSent(false); // Set back to false when answer is received
        scrollToBottomOfChat();

        // Deduct credits and update state
        const newCredits = credits - 0.4;
        setCredits(newCredits);

        // Update credits in Firestore
        const userRef = doc(db, "transcriptions", user.uid);
        await updateDoc(userRef, { credits: newCredits });

        // Save chat to Firestore
        const chatDocId = new Date().toISOString(); // Using ISO string for unique ID
        const chatDocRef = doc(db, `transcriptions/${user.uid}/entries/${docId}/chat/${chatDocId}`);

        await setDoc(chatDocRef, {
            question: question,
            answer: answer,
            timestamp: serverTimestamp() // Firebase server timestamp
        });

    } catch (error) {
        console.error("Error in getting answer or saving chat:", error);
        setIsQuestionSent(false); // Ensure to set it back to false if there's an error
    }
};

  const askMultiChatQuestion = async (question) => {
    // Check if the user has enough credits
    if (credits < 0.4) {
        alert("You don't have enough credits to ask a question.");
        return;
    }

    // Aggregate (concatenate) all selected transcriptions into one string
    const aggregatedTranscription = selectedTranscriptions
        .map(id => {
            const transcription = transcriptions.find(t => t.id === id);
            return transcription ? transcription.transcription : '';
        })
        .join(' '); // Concatenate using a space or another appropriate delimiter

    setIsQuestionSent(true); // Indicate that the question has been sent

    try {
        // Get the answer for the aggregated transcription
        const answer = await getAnswer(question, aggregatedTranscription);

        // Update multiChatHistory with the question and the received answer
        const newMultiChatHistory = [
            ...(multiChatHistory || []),
            { type: 'user', text: question },
            { type: 'bot', text: answer }
        ];
        setMultiChatHistory(newMultiChatHistory);

        // Deduct credits and update state
        const newCredits = credits - 0.4;
        setCredits(newCredits);

        // Update credits in Firestore
        const userRef = doc(db, "users", user.uid);
        await updateDoc(userRef, { credits: newCredits });

    } catch (error) {
        console.error("Error getting answer:", error);
    } finally {
        setIsQuestionSent(false); // Reset the status after receiving the answer or in case of an error
    }
  };    
  const fetchUserCredits = async (currentUser) => {
    const userRef = doc(db, "transcriptions", currentUser.uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      setCredits(docSnap.data().credits);
    } else {
      console.log("No such user document!");
    }
  };

  const handleUnauthenticatedUser = () => {
    console.log("User is not authenticated.");
    if (window.location.pathname !== '/login.html' && window.location.pathname !== '/register.html') {
      window.location.href = "/";
    }
  };

  const handleFileUpload = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    if (!audioFile) {
        alert("Please select a file to upload.");
        return;
    }

    // Check for valid audio file types
    const isValidAudioType = (type) => {
        return type.includes("audio") || type.includes("mp3") || 
               type.includes("m4a") || type.includes("wav") || 
               type.includes("webm");
    };

    if (!isValidAudioType(audioFile.type) && 
        audioFile.type !== "application/pdf" && 
        audioFile.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        alert("Invalid file type. Please select an audio, PDF, or Word document.");
        return;
    }

    setIsUploading(true); // Indicate that the upload is in progress

    // Determine the storage path based on file type
    let storagePath = `${user.uid}/`;
    if (isValidAudioType(audioFile.type)) {
        storagePath += `audio/${audioFile.name}`;
    } else if (audioFile.type === "application/pdf") {
        storagePath += `pdf/${audioFile.name}`;
    } else if (audioFile.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        storagePath += `docx/${audioFile.name}`;
    } else if (audioFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        storagePath += `excel/${audioFile.name}`;
        setExcelFile(audioFile);
    }

    const fileRef = ref(storage, storagePath);
    const uploadTask = uploadBytesResumable(fileRef, audioFile);

    uploadTask.on('state_changed',
        (snapshot) => {
            // Set progress to 85% during the upload
            const progress = Math.min((snapshot.bytesTransferred / snapshot.totalBytes) * 100, 85);
            setUploadProgress(progress);
        },
        (error) => {
            console.error("Error uploading file:", error);
            setIsUploading(false); // Reset uploading state in case of an error
        },
        async () => {
            try {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                console.log('File available at', downloadURL);
                await transcribeAudio(audioFile.name, user.uid); // Call transcribeAudio function after successful upload
            } catch (error) {
                console.error("Error in upload completion or transcription:", error);
                setIsUploading(false); // Reset uploading state in case of an error
            }
        }
    );
};



const transcribeAudio = (fileName, userId) => {
  const endpoint = "https://us-central1-whatsappinitiative.cloudfunctions.net/transcription_fcn";
  fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fileName: fileName, userId: userId }),
      mode: 'cors',
      credentials: 'same-origin'
  })
  .then(response => {
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
  })
  .then(data => {
      if (data.transcription && data.summary) {
          // Call fetchTranscriptions to refresh the list
          fetchTranscriptions(user); // Ensure 'user' is available in this scope
          console.log("Transcription and summary received:", data);

          // Set upload progress to 100% once transcription and summary are received
          setUploadProgress(100);
      } else {
          throw new Error("Invalid transcription data received from the Cloud Function.");
      }
  })
  .catch(error => {
      console.error("Error calling the Cloud Function:", error);
      setIsUploading(false); // Reset the uploading state in case of an error
  });
};
const deleteEntry = async (entryId) => {
  try {
      // Reference to the entry in Firestore
      const entryRef = doc(db, `transcriptions/${user.uid}/entries`, entryId);

      // Delete the entry from Firestore
      await deleteDoc(entryRef);

      // Update local state to reflect the deletion
      setTranscriptions(prevTranscriptions => prevTranscriptions.filter(entry => entry.id !== entryId));

      console.log(`Entry ${entryId} deleted successfully.`);
  } catch (error) {
      console.error("Error deleting entry:", error);
  }
};

      // Function to fetch chat history from Firestore
    const fetchChatHistory = async (docId) => {
        const chatCollectionRef = collection(db, `transcriptions/${user.uid}/entries/${docId}/chat`);
        const q = query(chatCollectionRef, orderBy("timestamp", "asc")); // Order by timestamp
        const querySnapshot = await getDocs(q);
        const fetchedChatHistory = querySnapshot.docs.map(doc => ({
            type: 'chat', // You can determine type based on the structure of your chat data
            text: doc.data().question + '\n' + doc.data().answer, // Example, adjust as needed
            ...doc.data()
        }));
        setChatHistory(fetchedChatHistory);
    };

    const viewTranscription = async (docId) => {
        try {
            const transcriptionDocRef = doc(db, `transcriptions/${user.uid}/entries/${docId}`);
            const transcriptionDoc = await getDoc(transcriptionDocRef);
            
            if (transcriptionDoc.exists()) {
                setSelectedTranscription({ id: docId, ...transcriptionDoc.data() });
                setIsModalOpen(true);
                await fetchChatHistory(docId); // Fetch and display the chat history
            } else {
                console.log("No such transcription!");
            }
        } catch (error) {
            console.error("Error getting transcription or fetching chat history:", error);
        }
    };
  
    // Function to close the modal
    const closeModal = () => {
      setIsModalOpen(false);
      setSelectedTranscription(null);
      setChatHistory([]); // Clear chat history when closing modal

    };

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
          closeModal();
        }
      };
      // Only add the event listener when the modal is open
      if (isModalOpen) {
        window.addEventListener('keydown', handleKeyDown);
      }

      // Cleanup the event listener when the modal is closed or component unmounts
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [isModalOpen]); // Dependencies array includes isModalOpen



    const sendCommonQuestion = async (question) => {
      // Simulate entering the question in the chat input and sending it
      if (selectedTranscription) {
        askQuestion(selectedTranscription.id, question);
      }
    };
    const sendMultiChatMessage = async (message) => {
      // Implement logic to send the message to all selected transcriptions
      // and update the multiChatHistory state
      // Example:
      try {
        const newMultiChatHistory = Array.isArray(multiChatHistory) ? [...multiChatHistory] : [];
        const answers = await Promise.all(selectedTranscriptions.map((transcriptionId) => {
          // Send the message to the backend and get a response for each transcription
          return getAnswer(message, transcriptions.find(t => t.id === transcriptionId).transcription);
        }));
    
        answers.forEach((answer, index) => {
          // Assuming the answer includes which transcription it belongs to
          newMultiChatHistory.push({ type: 'user', text: message });
          newMultiChatHistory.push({ type: 'bot', text: answer, transcriptionId: selectedTranscriptions[index] });
        });
    
        setMultiChatHistory(newMultiChatHistory);
      } catch (error) {
        console.error("Error sending multi-chat message:", error);
      }
    };
    const askExcelQuestion = async () => {
      if (!excelQuestion) {
          alert("Please enter a question.");
          return;
      }
      if (!excelFile) {
          alert("No Excel file selected.");
          return;
      }
  
      // Step 1: Upload the Excel file to Firebase Storage
      const storagePath = `${user.uid}/excel/${excelFile.name}`;
      const fileRef = ref(storage, storagePath);
  
      try {
          await uploadBytes(fileRef, excelFile);
          console.log('Excel file uploaded successfully.');
  
          // Step 2: Call the Cloud Function
          const endpoint = "https://us-central1-whatsappinitiative.cloudfunctions.net/excelChatFunction";
          const response = await fetch(endpoint, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ fileName: excelFile.name, userId: user.uid, question: excelQuestion })
          });
  
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data = await response.json();
          console.log("Response from Excel chat function:", data);
  
          // Step 3: Handle the response (e.g., display the answer)
          // Display the answer to the user (you can modify this part as per your UI design)
          alert(`Answer: ${data.answer}`);
  
      } catch (error) {
          console.error("Error processing Excel question:", error);
          alert("Failed to process the Excel question.");
      }
  };
    
  return (
    <div className='transcription-dashboard' id="transcription">
      {user ? (
        <div>
            <div className='user-credits-container'>
                <h1 className="user-info ">USER: {user.email}</h1>
                <h1 className="credits-info">CREDITS: {credits ? credits.toFixed(1) : '0.0'}</h1>
                <button onClick={buyCredits} className="axn__transcription_buy-credits-button"id="buyCredits">Buy Credits</button>
                <button onClick={toggleBillingModal} className="billing-button">Billing</button>
                <button onClick={() => signOut(auth)} className="sign-out-button">Sign Out</button>
            </div>
              <div className="upload-controls">
                <div className="youtube-link-submission">
                    <form onSubmit={handleYoutubeSubmit}>
                        <input 
                            type="text" 
                            value={youtubeLink}
                            onChange={(e) => setYoutubeLink(e.target.value)}
                            placeholder="Enter YouTube video link"
                        />
                        <button type="submit">Transcribe Video</button>
                    </form>
                </div>
                <form onSubmit={handleFileUpload}>
                  <div className="progress-bar-container">
                    <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                  </div>
                  <label className="custom-file-upload">
                    <input type="file" onChange={(e) => setAudioFile(e.target.files[0])} />
                    Choose File
                  </label>
                  <button type="submit" disabled={isUploading}>Upload File</button>
                </form>
                <button 
                className="multi-chat-button" 
                onClick={toggleMultiChatModal} 
                style={{ 
                  position: 'fixed', 
                  bottom: '20px', 
                  right: '20px', 
                  zIndex: 1002 // Make sure this is above your modal's z-index
                }}
              >
                Multi-Chat
              </button>
              </div>         
              <div className="search-bar-container">
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search transcriptions..."
                  onChange={handleSearchChange}
                />
              </div>
            <div>
              {transcriptions
                .filter(transcription => 
                  transcription.audioName.toLowerCase().includes(searchQuery) ||
                  transcription.date.toLowerCase().includes(searchQuery) ||
                  transcription.summary.toLowerCase().includes(searchQuery) // Add condition for summary
                )
                .map((transcription) => (
                  <div key={transcription.id} className="transcription-item">
                    <div className="transcription-item-header">
                      <div className="transcription-item-fileName-datetime">
                        <span>{transcription.audioName}</span>
                        <span>{transcription.date} at {transcription.time}</span>
                      </div>
                      <button onClick={() => toggleTranscriptionSelection(transcription.id)}>
                        {selectedTranscriptions.includes(transcription.id) ? 'Unselect' : 'Select'}
                      </button>
                      <button onClick={() => viewTranscription(transcription.id)}>Chat</button>
                      <button onClick={() => deleteEntry(transcription.id)}>Delete</button>
                    </div>
                    <div className="transcription-item-summary">
                    <div className={`transcription-item-summary ${expandedSummaryId === transcription.id ? 'expanded' : ''}`}
                        onClick={() => toggleSummary(transcription.id)}>
                          {transcription.summary}
                      </div>                    
                    </div>
                  </div>
              ))}
            </div>
        </div>
      ) : (
        <p>Loading or user not authenticated...</p>
      )}
      {isBillingModalOpen && (
        <div className="modal-backdrop">
          <div className="modal">
            <h3>Billing Information</h3>
            <button onClick={toggleBillingModal} className="modalCloseButton">Close</button>
            <div className="billing-data">
              <div className="billing-header">
                <div className="billing-column">Date</div>
                <div className="billing-column">Quantity</div>
                <div className="billing-column">Price</div>
              </div>
              {billingData.map((transaction, index) => (
                <div key={index} className="billing-row">
                  <div className="billing-column">{transaction.created}</div>
                  <div className="billing-column">{transaction.quantity}</div>
                  <div className="billing-column">{transaction.price}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    {isMultiChatModalOpen && (
          <div className="modal-backdrop" style={{ zIndex: 1001 }}>
              <div className="modal multi-chat-modal">
                  <div className="modal-content">
                  <div className="left-section">
                          <h4>Selected Transcriptions</h4>
                          <ul>
                              {selectedTranscriptions.map((transcriptionId) => {
                                  const transcription = transcriptions.find((t) => t.id === transcriptionId);
                                  return (
                                      <li key={transcriptionId}>
                                          {transcription.audioName} - {transcription.date}
                                      </li>
                                  );
                              })}
                          </ul>
                      </div>
                      <div className="right-section">
                          <h4 className='gradient__text'>Group Chat</h4>
                          <div className="chat-area">
                              <div className="chat-history">
                                  {multiChatHistory.map((chat, index) => (
                                      <div key={index} className={`chat-message ${chat.type}`}>
                                          {chat.text}
                                      </div>
                                  ))}
                                  {isQuestionSent && <div className="ellipsis"></div>} {/* Waiting animation */}
                              </div>

                              <div className="common-questions">
                                  <h5>Common Questions:</h5>
                                  <ul>
                                      {commonQuestions.map((question, index) => (
                                          <li key={index}>
                                            <div className='qButton'>

                                              <button
                                                  onClick={() => askMultiChatQuestion(question)}
                                                  className="common-question-button"
                                              >
                                                  {question}
                                              </button>
                                            </div>
                                          </li>
                                      ))}
                                  </ul>
                              </div>

                              <textarea 
                                  id="groupChatInput" 
                                  placeholder="Type your message..."
                                  onKeyDown={(e) => {
                                      if (e.key === 'Enter' && !e.shiftKey) {
                                          e.preventDefault();
                                          askMultiChatQuestion(e.target.value);
                                          e.target.value = ''; // Clear the input field
                                      }
                                  }}
                              />
                          </div>
                      </div>

                      <button className="modalCloseButton" onClick={toggleMultiChatModal}>Close</button>
                  </div>
              </div>
          </div>
      )}


      {isModalOpen && selectedTranscription && (
                <div className="modal-backdrop">
                    <div className="modal">
                        <h3>{selectedTranscription.audioName}</h3>

                        <div className="modal-content">
                            <div className="left-section">
                                <h4>Transcription</h4>
                                <div className="scrollable-content">
                                    {selectedTranscription.transcription}
                                </div>
                                <h4>Summary</h4>
                                <div className="transcription-summary">
                                  {selectedTranscription.summary}
                                </div>
                            </div>
                            <div className="right-section">
                                <h4 className='gradient__text'>Chat</h4>
                                    <div className="chat-history">
                                    {chatHistory.map((chat, index) => (
                                      <div key={index} className={`chat-message ${chat.type}`}>
                                        <div className="chat-message-content">{chat.text}</div>
                                      </div>
                                    ))}
                                        {isQuestionSent && <div className="ellipsis"></div>}
                                        <div className="common-questions">
                                          <h5>Common Questions:</h5>
                                          <ul>
                                            {commonQuestions.map((question, index) => (
                                              <li key={index}>
                                                <div className='qButton'>
                                                  <button
                                                    onClick={() => sendCommonQuestion(question)}
                                                    className="common-question-button"
                                                  >
                                                    {question}
                                                  </button>
                                                </div>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                        <div ref={chatHistoryEndRef} /> {/* Invisible element at the end of the chat history */}
                                </div>
                                
                                <textarea 
                                        id="chatInput" 
                                        placeholder="Ask a question about the transcript..."
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                askQuestion(selectedTranscription.id, e.target.value);
                                                e.target.value = ''; // Clear the input field
                                            }
                                        }}
                                  />
                                  <button class="modalCloseButton" onClick={closeModal}>Close</button>
                            </div>
                        </div>

                        
                    </div>
                </div>
            )}


    </div>
  );
};

export default TranscriptionDashboard;