import React from "react";
import useInView from '../inView/useInView';


const ContactForm = () => {
    const [setRef, inView] = useInView({
        threshold: 0.65, // Trigger when the section is at least 10% in view
      });

    return (
        <section 
          className={`Contact-Us ${inView ? 'in-view' : ''}`}
          ref={setRef} // Set the ref to observe this section
        >
        <div className="contactus-section mt-6">
            <div className="row">
                <div className="col-md-6 d-flex align-items-center">
                    <div className="left pe-5">
                        <h2>Contact us</h2>
                        <p>Got a question? Just ask! Drop us a line at <a href="mailto:support@axn.ai">support@axn.ai</a>, and let's get you started on a smarter way to work. Whether it's a chat with Hannah or setting up with Alice, we're here to help you make the most out of your day. <br /> <br/>We value your feedback! Take a moment to share your thoughts and experiences with us through a testimonial at <a href="mailto:support@axn.ai">support@axn.ai</a> </p>
                    </div>
                </div>
            </div>
        </div>
        </section>
    );

}

export default ContactForm;