import React from 'react';

const Ecard = (props) => {
    return (
        <div className="col-xl-3 col-md-4 col-sm-6 ">
            <div className="card ex-card d-flex flex-column justify-content-center border-0 mb-3">
                <div className='background-image2'></div>
                <div className="c-icon">
                    <img src={props.icon} />
                </div>
                <div className="c-heading pt-3">
                    <h3>{props.heading}</h3>
                </div>
                <div className="c-body" style={{ minHeight: '150px' }}>
                    <p>{props.desc}</p>
                </div>
            </div>
        </div>
    )
}

export default Ecard