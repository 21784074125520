import React from "react";

const TwoStepsLfCard = (props) => {

    return (
        <div className="box-shadow-steps card border-r-15 border-0 p-4 my-4">
            <div className="row align-items-center">
                <div className="col-md-6">
                    <div className="left pe-5">
                        <h3>{props.heading}</h3>
                        <p>{props.desc}</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="right">
                        <video autoPlay muted loop className="card-video">
                        <source src= {props.vid} type="video/mp4" />
                        Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TwoStepsLfCard;

