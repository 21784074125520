import React from "react";

const AgentCard = (props) => {

    return (

        <div className="col-xl-4 col-md-6 col-6">
            <div className="border-0 agent-card mx-2 mt-3">
                <img src={props.img} className="card-img" alt="" />
                <div className="body mt-3 pt-1 ps-1">
                    <h3 className="">{props.heading}</h3>
                    <p className="ps-1">{props.info}</p>
                </div>
            </div>
        </div>
    );
}

export default AgentCard;