import React, { useState } from 'react';
import './footer.css';
import useInView from '../../components/inView/useInView';

const Modal = ({ isOpen, onClose, children, type }) => {
  if (!isOpen) return null;

  // Determine the modal class name based on the type
  const modalClassName = `modal-content ${type}-modal`;

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className={modalClassName} onClick={(e) => e.stopPropagation()}>
        <button className="modal-close-btn" onClick={onClose}>Close</button>
        {children}
      </div>
    </div>
  );
};


const Footer = ({isDarkMode}) => {
  const [setRef, inView] = useInView({
    threshold: 0.5,
  });
  const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [isTermsOpen, setTermsOpen] = useState(false); // State for Terms & Conditions modal
  const [isDisclaimerOpen, setDisclaimerOpen] = useState(false); // State for Terms & Conditions modal
  
  const logoSvgLight = (
    <svg width="85" height="51" viewBox="0 0 85 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* SVG paths for light logo */}
        <path d="M64.3857 22.1083C64.3232 22.8114 64.2088 23.5144 64.2067 24.2175C64.1922 29.228 64.188 34.2384 64.2213 39.2469C64.2213 40.0059 64.0299 40.2838 63.2392 40.2631C61.7329 40.2112 60.2244 40.2319 58.716 40.2631C58.0918 40.2631 57.8192 40.0702 57.8192 39.3983C57.8275 30.1571 57.8275 20.9159 57.8192 11.6747C57.8192 10.5817 57.8504 10.5693 58.9698 10.5651C60.0309 10.5651 61.0941 10.6149 62.1511 10.5444C63.0041 10.4884 63.5825 10.8306 64.0944 11.4486C65.7797 13.4872 67.4796 15.5113 69.1773 17.5396C71.749 20.6151 74.3144 23.6949 76.911 26.7476C77.1748 27.0162 77.5328 27.1723 77.9097 27.1831C79.9134 27.1935 81.917 27.1624 83.9206 27.1002C84.7134 27.0753 85.0254 27.3635 84.9984 28.162C84.9568 29.4685 84.9797 30.7792 84.9984 32.0878C84.9984 32.6913 84.7903 32.9672 84.1204 32.963C80.9994 32.9423 77.8639 32.9298 74.7388 32.9879C73.7173 33.0086 73.1909 32.3201 72.6936 31.7228C70.2738 28.8194 67.9373 25.8351 65.557 22.8923C65.2617 22.5679 64.9455 22.2631 64.6104 21.9798L64.3857 22.1083Z" fill="#161616"/>
        <path d="M19.0156 27.1064C21.4041 27.1064 23.5701 27.0815 25.7339 27.1333C25.9235 27.1549 26.1059 27.2177 26.2684 27.3174C26.4309 27.417 26.5695 27.551 26.6743 27.7099C27.6044 29.3876 28.4574 31.1048 29.3479 32.8054C29.5706 33.2326 29.8327 33.6349 30.149 34.1783C31.4098 32.6872 32.602 31.2749 33.7984 29.8605C35.2132 28.1903 36.6308 26.5257 38.0512 24.8666C39.5971 23.0561 41.1388 21.2414 42.6972 19.4413C43.9664 17.973 45.271 16.5379 46.5381 15.0634C47.5284 13.9103 48.5147 12.7531 49.4509 11.5502C50.5349 10.1462 52.0933 10.0985 53.144 11.5191C53.6059 12.1413 54.0221 12.8028 54.434 13.4603C54.9583 14.2898 54.8502 15.0882 54.226 15.8307C50.9344 19.6784 47.645 23.5262 44.3576 27.3739C42.2124 29.8916 40.084 32.4217 37.943 34.9394C36.5448 36.5819 35.1425 38.2203 33.7173 39.842C33.5237 40.0631 33.2559 40.2061 32.9641 40.2444C30.8543 40.3398 28.7425 40.4352 26.6327 40.4186C26.3843 40.3911 26.1452 40.3087 25.9329 40.1774C25.7205 40.0461 25.5402 39.8691 25.4052 39.6595C23.6512 36.3123 21.9721 32.9277 20.2702 29.5535L19.0156 27.1064Z" fill="#161616"/>
        <path d="M84.8465 22.745C82.9739 22.745 81.2179 22.7658 79.4681 22.716C79.2891 22.716 78.9833 22.3012 78.977 22.071C78.9292 20.6815 78.9604 19.2879 78.9479 17.8984C78.9125 13.7921 77.2834 10.4324 73.9315 8.06405C72.2233 6.85084 70.228 6.07313 68.0642 6.05239C60.6759 5.98188 53.2917 5.94248 45.8972 5.8927C44.7008 5.8927 44.6072 5.79731 44.6051 4.6152C44.603 3.43309 44.6051 2.28001 44.6051 1.11034C44.5947 0.380339 44.9443 0.0443714 45.66 0.0443714C53.0296 0.0443714 60.4012 -0.0821359 67.7645 0.0920697C72.0798 0.19369 75.906 1.84657 79.0665 4.90139C81.6069 7.35064 83.4795 10.1607 84.4033 13.5951C84.9651 15.6959 84.784 17.8258 84.8444 19.9494C84.8673 20.8371 84.8465 21.7247 84.8465 22.745Z" fill="#161616"/>
        <path d="M5.71175 22.5854C3.92658 22.5854 2.25376 22.602 0.583015 22.5605C0.45642 22.5259 0.34073 22.4598 0.246826 22.3684C0.152921 22.277 0.0838867 22.1632 0.0462136 22.0379C0.112794 18.5724 -0.369911 15.0697 1.00538 11.7058C2.07695 9.09496 3.67858 6.73314 5.70967 4.76872C8.273 2.25311 11.371 0.888499 14.8311 0.233154C15.6815 0.093034 16.5432 0.0326287 17.4049 0.0527278C24.4124 0.0389019 31.4206 0.0389019 38.4296 0.0527278C39.6613 0.0527278 40.0545 0.436395 40.0379 1.69524C40.0254 2.80476 39.9214 3.91636 39.9505 5.02381C39.9713 5.86788 39.576 6.06075 38.8353 6.06075C31.4345 6.04831 24.0358 6.07941 16.6371 6.04001C14.1175 6.02549 12.0077 7.02095 10.1081 8.49133C7.68626 10.3578 6.26104 12.9108 5.77001 15.9117C5.58275 17.0523 5.718 18.2448 5.71175 19.4124C5.70967 20.4286 5.71175 21.4531 5.71175 22.5854Z" fill="#161616"/>
        <path d="M56.5728 50.9788H46.4443C44.9983 50.9788 44.7798 50.7486 44.7798 49.2948C44.7798 48.3754 44.7798 47.4574 44.7798 46.5407C44.7798 45.3462 44.8235 45.2964 45.9949 45.2964C52.3866 45.2964 58.7783 45.3379 65.1679 45.2549C67.0758 45.23 69.0108 45.3026 70.8958 44.668C73.8795 43.6622 76.0579 41.7459 77.3978 38.9856C78.0511 37.6375 78.8376 37.1191 80.2857 37.2829C81.326 37.397 82.4038 37.2974 83.4649 37.2974C84.2971 37.2974 84.3866 37.4405 84.1577 38.2079C83.3442 40.9433 81.7983 43.1852 79.8321 45.2466C77.9017 47.309 75.4987 48.8745 72.8287 49.8091C71.3973 50.3069 69.8618 50.7175 68.3575 50.7693C64.4376 50.9 60.5094 50.8129 56.5853 50.8129L56.5728 50.9788Z" fill="#161616"/>
        <path d="M0.691467 37.4654C2.53074 37.4654 4.31175 37.4467 6.10109 37.4882C6.3258 37.4882 6.59628 37.7868 6.75857 38.0108C7.7323 39.363 8.57703 40.8354 9.68184 42.0797C11.3047 43.8985 13.5331 44.7156 15.8904 45.1221C16.8236 45.2595 17.7666 45.3192 18.7097 45.3005C25.2969 45.3088 31.8856 45.3088 38.4756 45.3005C39.724 45.3005 40.0194 45.7588 39.828 47.0197C39.7093 47.9829 39.6849 48.9553 39.7552 49.9232C39.7926 50.7527 39.3786 50.9891 38.6566 50.9871C37.1773 50.9871 35.698 50.9871 34.2187 50.9871C28.7466 50.9871 23.2766 51.0161 17.8067 50.9871C13.6121 50.958 9.93152 49.4814 6.68575 46.8683C3.95181 44.6679 2.18119 41.8143 1.00356 38.5583C0.939062 38.3799 0.907852 38.1912 0.855837 38.0087C0.803821 37.8262 0.760128 37.6873 0.691467 37.4654Z" fill="#161616"/>
        <path d="M16.088 20.1879C15.5949 20.949 15.1268 21.5505 14.796 22.2162C14.5005 22.8114 14.101 22.9441 13.481 22.9296C11.8352 22.8881 10.1874 22.9296 8.54162 22.9006C8.2004 22.9006 7.86126 22.7222 7.39728 22.5895C8.26698 21.1149 9.01808 19.7918 9.81912 18.4977C11.2756 16.1584 12.759 13.8481 14.1884 11.5046C14.3546 11.1873 14.6111 10.9261 14.9258 10.7538C15.2404 10.5814 15.5992 10.5057 15.957 10.5361C18.4121 10.5713 18.4079 10.5361 19.5377 12.7282C21.1003 15.754 22.6774 18.7694 24.2399 21.7952C24.6956 22.6807 24.5437 22.913 23.5408 22.9151C21.7827 22.9151 20.0246 22.9047 18.2665 22.9151C17.7983 22.9151 17.5424 22.8238 17.3406 22.3261C17.0763 21.65 16.6082 21.0444 16.088 20.1879Z" fill="#161616"/>
        <path d="M8.06937 27.2661C10.3851 27.2661 12.7008 27.291 15.0145 27.2516C15.6845 27.2412 16.1276 27.459 16.4272 28.0293C17.1555 29.4229 17.8837 30.8186 18.614 32.2081C18.9115 32.7702 18.6972 32.9713 18.125 32.9361C17.3261 32.8884 16.5271 32.7888 15.7261 32.7868C10.8179 32.7702 5.9076 32.7619 0.999411 32.7868C0.231661 32.7868 -0.0409012 32.5897 0.00487255 31.8037C0.0652096 30.6099 0.0652096 29.4139 0.00487255 28.2201C-0.0304981 27.4797 0.250386 27.2516 0.955718 27.2578C3.32555 27.2827 5.69746 27.2682 8.06729 27.2682L8.06937 27.2661Z" fill="#161616"/>
        <path d="M45.0087 32.4011L48.8017 27.71C49.713 28.7822 50.5411 29.732 51.3442 30.7046C52.3325 31.8992 53.2688 33.1373 54.2841 34.307C54.9583 35.0826 54.7793 35.8437 54.3195 36.5509C53.5773 37.6997 52.773 38.8074 51.9102 39.8691C51.5523 40.3067 51.0197 40.2175 50.6347 39.7198C49.4093 38.1353 48.1713 36.5613 46.9479 34.9768C46.3133 34.1535 45.6974 33.3177 45.0087 32.4011Z" fill="#161616"/>
        <path d="M35.4566 21.93C33.8025 19.8292 32.1443 17.7387 30.5047 15.6316C29.8472 14.7876 29.8493 14.1281 30.4589 13.2156C30.8272 12.666 31.2017 12.1206 31.5908 11.5855C32.6811 10.0944 33.9169 10.1151 35.0342 11.5565C36.3013 13.1907 37.6184 14.7855 38.95 16.3679C39.3952 16.8967 39.595 17.2741 39.0498 17.915C38.3799 18.7072 37.9513 19.7027 37.2834 20.497C36.8194 21.0507 36.1307 21.4157 35.4566 21.93Z" fill="#161616"/>
    </svg>
  );

  const logoSvgDark = (
    <svg width="85" height="51" viewBox="0 0 85 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* SVG paths for dark logo */}
        <path d="M64.3855 22.108C64.3231 22.811 64.2087 23.5141 64.2066 24.2171C64.192 29.2276 64.1879 34.2381 64.2212 39.2465C64.2212 40.0055 64.0298 40.2834 63.2391 40.2627C61.7327 40.2108 60.2243 40.2316 58.7158 40.2627C58.0917 40.2627 57.8191 40.0698 57.8191 39.3979C57.8274 30.1567 57.8274 20.9155 57.8191 11.6743C57.8191 10.5814 57.8503 10.5689 58.9697 10.5648C60.0308 10.5648 61.094 10.6145 62.1509 10.544C63.004 10.488 63.5824 10.8302 64.0942 11.4482C65.7796 13.4869 67.4794 15.511 69.1772 17.5392C71.7489 20.6148 74.3143 23.6945 76.9109 26.7472C77.1747 27.0158 77.5327 27.1719 77.9096 27.1828C79.9132 27.1931 81.9169 27.162 83.9205 27.0998C84.7132 27.0749 85.0253 27.3632 84.9983 28.1616C84.9567 29.4682 84.9796 30.7789 84.9983 32.0875C84.9983 32.691 84.7902 32.9668 84.1203 32.9626C80.9993 32.9419 77.8638 32.9295 74.7387 32.9875C73.7171 33.0083 73.1907 32.3197 72.6935 31.7225C70.2737 28.819 67.9372 25.8347 65.5569 22.8919C65.2616 22.5675 64.9454 22.2627 64.6102 21.9794L64.3855 22.108Z" fill="white"/>
        <path d="M19.0156 27.1067C21.4042 27.1067 23.5701 27.0818 25.734 27.1337C25.9235 27.1553 26.106 27.2181 26.2685 27.3177C26.431 27.4173 26.5696 27.5513 26.6744 27.7102C27.6044 29.388 28.4575 31.1052 29.348 32.8057C29.5706 33.233 29.8328 33.6353 30.149 34.1786C31.4099 32.6875 32.6021 31.2752 33.7985 29.8608C35.2133 28.1907 36.6309 26.526 38.0513 24.8669C39.5972 23.0564 41.1389 21.2418 42.6973 19.4417C43.9665 17.9734 45.271 16.5383 46.5381 15.0637C47.5285 13.9107 48.5147 12.7534 49.451 11.5506C50.535 10.1466 52.0934 10.0989 53.1441 11.5195C53.606 12.1416 54.0221 12.8032 54.4341 13.4606C54.9584 14.2902 54.8502 15.0886 54.226 15.8311C50.9345 19.6788 47.645 23.5265 44.3576 27.3743C42.2125 29.8919 40.084 32.4221 37.9431 34.9398C36.5449 36.5823 35.1425 38.2206 33.7173 39.8424C33.5238 40.0634 33.256 40.2065 32.9641 40.2447C30.8544 40.3401 28.7425 40.4355 26.6328 40.4189C26.3844 40.3915 26.1453 40.3091 25.9329 40.1777C25.7206 40.0464 25.5403 39.8695 25.4052 39.6599C23.6513 36.3127 21.9722 32.9281 20.2702 29.5539L19.0156 27.1067Z" fill="white"/>
        <path d="M84.8465 22.745C82.9739 22.745 81.2179 22.7658 79.4681 22.716C79.2891 22.716 78.9833 22.3012 78.977 22.071C78.9292 20.6815 78.9604 19.2879 78.9479 17.8984C78.9125 13.7921 77.2834 10.4324 73.9315 8.06405C72.2233 6.85084 70.228 6.07313 68.0642 6.05239C60.6759 5.98188 53.2917 5.94248 45.8972 5.8927C44.7008 5.8927 44.6072 5.79731 44.6051 4.6152C44.603 3.43309 44.6051 2.28001 44.6051 1.11034C44.5947 0.380339 44.9443 0.0443714 45.66 0.0443714C53.0296 0.0443714 60.4012 -0.0821359 67.7645 0.0920697C72.0798 0.19369 75.906 1.84657 79.0665 4.90139C81.6069 7.35064 83.4795 10.1607 84.4033 13.5951C84.9651 15.6959 84.784 17.8258 84.8444 19.9494C84.8673 20.8371 84.8465 21.7247 84.8465 22.745Z" fill="white"/>
        <path d="M5.71188 22.585C3.9267 22.585 2.25388 22.6016 0.583137 22.5601C0.456542 22.5256 0.340852 22.4594 0.246948 22.368C0.153043 22.2766 0.0840088 22.1629 0.0463357 22.0375C0.112916 18.5721 -0.369789 15.0693 1.0055 11.7055C2.07707 9.09459 3.6787 6.73277 5.70979 4.76836C8.27312 2.25274 11.3712 0.888133 14.8312 0.232788C15.6816 0.0926678 16.5433 0.0322625 17.405 0.0523616C24.4125 0.0385357 31.4208 0.0385357 38.4297 0.0523616C39.6614 0.0523616 40.0547 0.436028 40.038 1.69487C40.0255 2.8044 39.9215 3.91599 39.9506 5.02344C39.9714 5.86751 39.5761 6.06038 38.8354 6.06038C31.4346 6.04794 24.0359 6.07905 16.6372 6.03964C14.1176 6.02513 12.0078 7.02059 10.1082 8.49096C7.68639 10.3575 6.26116 12.9104 5.77013 15.9113C5.58288 17.0519 5.71812 18.2444 5.71188 19.412C5.70979 20.4282 5.71188 21.4527 5.71188 22.585Z" fill="white"/>
        <path d="M56.5728 50.979H46.4443C44.9983 50.979 44.7798 50.7488 44.7798 49.2951C44.7798 48.3756 44.7798 47.4576 44.7798 46.541C44.7798 45.3464 44.8235 45.2966 45.9949 45.2966C52.3865 45.2966 58.7782 45.3381 65.1678 45.2551C67.0758 45.2303 69.0107 45.3028 70.8958 44.6682C73.8794 43.6624 76.0578 41.7461 77.3977 38.9858C78.051 37.6378 78.8375 37.1193 80.2856 37.2832C81.326 37.3972 82.4037 37.2977 83.4648 37.2977C84.2971 37.2977 84.3866 37.4408 84.1577 38.2081C83.3442 40.9436 81.7983 43.1854 79.8321 45.2468C77.9017 47.3093 75.4986 48.8748 72.8287 49.8094C71.3972 50.3071 69.8617 50.7177 68.3574 50.7696C64.4375 50.9002 60.5093 50.8131 56.5852 50.8131L56.5728 50.979Z" fill="white"/>
        <path d="M0.691406 37.4651C2.53068 37.4651 4.31169 37.4465 6.10103 37.4879C6.32574 37.4879 6.59622 37.7866 6.75851 38.0106C7.73224 39.3627 8.57697 40.8352 9.68178 42.0795C11.3047 43.8983 13.533 44.7154 15.8904 45.1219C16.8235 45.2593 17.7665 45.3189 18.7096 45.3002C25.2969 45.3085 31.8855 45.3085 38.4755 45.3002C39.7239 45.3002 40.0194 45.7586 39.828 47.0195C39.7092 47.9826 39.6848 48.955 39.7551 49.9229C39.7926 50.7525 39.3785 50.9889 38.6566 50.9868C37.1772 50.9868 35.6979 50.9868 34.2186 50.9868C28.7465 50.9868 23.2766 51.0158 17.8066 50.9868C13.6121 50.9578 9.93146 49.4812 6.68569 46.8681C3.95175 44.6677 2.18113 41.814 1.0035 38.5581C0.939001 38.3797 0.907791 38.191 0.855776 38.0085C0.80376 37.826 0.760067 37.687 0.691406 37.4651Z" fill="white"/>
        <path d="M16.088 20.1877C15.5949 20.9488 15.1267 21.5502 14.7959 22.2159C14.5005 22.8111 14.101 22.9439 13.481 22.9293C11.8352 22.8879 10.1873 22.9293 8.54156 22.9003C8.20034 22.9003 7.8612 22.722 7.39722 22.5892C8.26692 21.1147 9.01802 19.7916 9.81906 18.4975C11.2755 16.1581 12.759 13.8478 14.1884 11.5044C14.3545 11.1871 14.611 10.9259 14.9257 10.7535C15.2404 10.5812 15.5992 10.5055 15.9569 10.5358C18.412 10.5711 18.4079 10.5359 19.5377 12.7279C21.1002 15.7537 22.6773 18.7691 24.2399 21.7949C24.6955 22.6805 24.5436 22.9127 23.5408 22.9148C21.7827 22.9148 20.0245 22.9045 18.2664 22.9148C17.7983 22.9148 17.5423 22.8236 17.3405 22.3258C17.0763 21.6498 16.6081 21.0442 16.088 20.1877Z" fill="white"/>
        <path d="M8.06937 27.2658C10.3851 27.2658 12.7008 27.2907 15.0145 27.2513C15.6845 27.241 16.1276 27.4587 16.4272 28.029C17.1555 29.4227 17.8837 30.8184 18.614 32.2079C18.9115 32.7699 18.6972 32.9711 18.125 32.9358C17.3261 32.8881 16.5271 32.7886 15.7261 32.7865C10.8179 32.7699 5.9076 32.7616 0.999411 32.7865C0.231661 32.7865 -0.0409012 32.5895 0.00487255 31.8035C0.0652096 30.6097 0.0652096 29.4136 0.00487255 28.2198C-0.0304981 27.4795 0.250386 27.2513 0.955718 27.2575C3.32555 27.2824 5.69746 27.2679 8.06729 27.2679L8.06937 27.2658Z" fill="white"/>
        <path d="M45.0087 32.4011L48.8017 27.71C49.713 28.7822 50.5411 29.732 51.3442 30.7046C52.3325 31.8992 53.2688 33.1373 54.2841 34.307C54.9583 35.0826 54.7793 35.8437 54.3195 36.5509C53.5773 37.6997 52.773 38.8074 51.9102 39.8691C51.5523 40.3067 51.0197 40.2175 50.6347 39.7198C49.4093 38.1353 48.1713 36.5613 46.9479 34.9768C46.3133 34.1535 45.6974 33.3177 45.0087 32.4011Z" fill="white"/>
        <path d="M35.4567 21.9304C33.8026 19.8295 32.1443 17.7391 30.5048 15.632C29.8473 14.7879 29.8494 14.1284 30.459 13.2159C30.8273 12.6664 31.2018 12.1209 31.5909 11.5859C32.6811 10.0948 33.917 10.1155 35.0343 11.5568C36.3014 13.1911 37.6184 14.7859 38.95 16.3682C39.3953 16.8971 39.595 17.2745 39.0499 17.9153C38.3799 18.7076 37.9513 19.703 37.2835 20.4973C36.8195 21.051 36.1308 21.416 35.4567 21.9304Z" fill="white"/>
    </svg>
  );
  return (
    <section 
    className={`footer ${inView ? 'in-view' : ''}`}
    ref={setRef}
    >
      <footer className='mt-2'>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="left">
              {isDarkMode ? logoSvgDark : logoSvgLight}
                <p className='mt-3 txt-black2'>Connect with the Future – Follow AXN. ai into a Smarter Tomorrow.</p>
                {/* Social icons and other columns omitted for brevity */}
              </div>
            </div>
            <div className="col-md-12">
              <hr />
            </div>
            <div className="col-md-12">
              <div className="bottom d-flex justify-content-between align-items-center mt-3 mb-2">
                <p className='txt-black2'>© 2024 AXN.ai. All rights reserved.</p>
                <div>
                  <button className='me-3 txt-black2 privacy-policy-btn' onClick={() => setPrivacyPolicyOpen(true)} style={{ background: 'none', border: 'none', padding: 0, color: 'inherit', font: 'inherit', cursor: 'pointer' }}>Privacy Policy</button>
                  <button className='me-3 txt-black2 terms-conditions-btn' onClick={() => setTermsOpen(true)} style={{ background: 'none', border: 'none', padding: 0, color: 'inherit', font: 'inherit', cursor: 'pointer' }}>Terms & Conditions</button>
                  <button className='me-3 txt-black2 disclaimer-btn' onClick={() => setDisclaimerOpen(true)} style={{ background: 'none', border: 'none', padding: 0, color: 'inherit', font: 'inherit', cursor: 'pointer' }}>Disclaimer</button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/*  Disclaimer Modal */}

      <Modal className="disclaimer-modal " isOpen={isDisclaimerOpen} onClose={() => setDisclaimerOpen(false)} type="disclaimer-modal">
      <h1 class="privacy-policy-heading">Disclaimer</h1>
      <h2 class="disclaimer-heading">Disclaimer</h2>

      <p class="disclaimer-content">AXN.ai, a subsidiary of Kiwix LLC registered in Delaware, US, operates as an AI powered software website offering a range of AI enabled services, including media recording, transcription, translation, and summarization. The platform encompasses AI tools such as Alice, a digital meeting assistant, and Hannah, integrated on WhatsApp, offering diverse communication and media interaction capabilities to users across various demographics.</p>

      <h3 class="disclaimer-subheading">Scope of Information</h3>
      <p class="disclaimer-content">Accuracy and Reliability: While AXN.ai strives to provide accurate and reliable AI-powered services, the platform cannot guarantee the absolute accuracy, completeness, or suitability of its services for every user's specific needs or circumstances. Users understand that AI tools have inherent limitations and potential inaccuracies.</p>
      <p class="disclaimer-content">Third-Party Content: AXN.ai may provide access to third-party content and services. The platform disclaims any liability for the accuracy, reliability, or legality of third-party content, as users interact with such content at their own discretion and risk.</p>
      <p class="disclaimer-content">User Responsibility: Users are solely responsible for the accuracy, legality, and appropriateness of the input, content, and interactions they engage in within AXN.ai platform. The platform disclaims any liability for user-generated content, interactions, or related decisions.</p>
      <p class="disclaimer-content">No Outcome Guarantees: Users acknowledge that while AXN.ai aims to deliver high-quality AI-powered services, the platform does not guarantee specific outcomes, results, or performance. AI tools serve as aids and do not replace individual judgment or professional advice.</p>
      <p class="disclaimer-content">Indemnification and Non-Liability:  Users agree to indemnify and hold AXN.ai, its subsidiaries, affiliates, employees, and agents harmless from any claims, losses, damages, or liabilities arising from their use of the platform's services. AXN.ai does not assume liability for any indirect, incidental, consequential, or punitive damages.</p>
      <p class="disclaimer-content">Legal Compliance and Jurisdiction: Users’ use of AXN.ai's services is subject to compliance with all applicable laws and regulations. Legal disputes related to the platform's services and this disclaimer fall under the jurisdiction of Delaware, US.</p>
      <p class="disclaimer-content">Acknowledgment and Acceptance: By using AXN.ai's services, users explicitly acknowledge and accept the provisions outlined in this general disclaimer. They agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits to the fullest extent permitted by law.</p>
      <p class="disclaimer-content">Informational Purpose: The content provided by AXN.AI on its website and mobile application is solely for general informational purposes. It is not intended as, nor should it be considered as, professional or expert advice.</p>
      <p class="disclaimer-content">No Warranty on Information: While we endeavor to keep the information up to date and correct, AXN.AI makes no representations, warranties, or guarantees, express or implied, as to the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the website or mobile application.</p>

      <h3 class="disclaimer-subheading">User Responsibility</h3>
      <p class="disclaimer-content">Accuracy and Legality of User Input: Users are responsible for the accuracy, legality, and ethical nature of the input, content, and interactions they provide within AXN.ai's platform. This includes ensuring that any content uploaded or communicated complies with copyright laws, does not infringe on the intellectual property rights of others, and adheres to applicable regulations and guidelines.</p>
      <p class="disclaimer-content">Informed Decision-Making: Users are encouraged to exercise informed judgment when utilizing AXN.ai's services. While the platform provides AI-enabled tools and assistance, users recognize that their decisions and actions based on the use of the services are their own responsibility.</p>
      <p class="disclaimer-content">Data Privacy and Security: Users must ensure the confidentiality and security of any sensitive information or data shared with AXN.ai. It is the user’s responsibility to comply with data privacy regulations and safeguard their personal and organizational data.</p>
      <p class="disclaimer-content">Compliance with Policies and Guidelines: Users agree to comply with AXN.ai's terms of service, community guidelines, and all applicable laws and regulations when using the platform's services. This includes refraining from engaging in any malicious, fraudulent, or illegal activities that could compromise the integrity of the platform and its community.</p>
      <p class="disclaimer-content">Ethical Use and Conduct: Users are responsible for maintaining ethical conduct when interacting with AI entities such as Hannah and utilizing the platform's features. This involves refraining from abusive, discriminatory, or inappropriate behavior in all forms of communication and engagement.</p>
      <p class="disclaimer-content">Legal Compliance and Acknowledgment: By using AXN.ai's services, users explicitly acknowledge and accept their responsibility to comply with the outlined user responsibilities. They agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits arising from their failure to meet these responsibilities to the fullest extent permitted by law.</p>
      <p class="disclaimer-content">Verification and Use: Users are advised to independently verify any information obtained from our website and mobile application before acting upon it. The use of any information from AXN.AI is solely at the user's own risk.</p>

      <h3 class="disclaimer-subheading">External Links Disclaimer</h3>
      <p class="disclaimer-content">Third-Party Links: Third-Party Content and Integration: AXN.ai may provide access to external websites, platforms, or content through hyperlinks, integrations, or references. The inclusion of external links does not imply endorsement or validation of the content, accuracy, or legality of third-party websites or platforms. Users acknowledge that they interact with external links at their own discretion and risk.</p>
      <p class="disclaimer-content">Responsibility for External Interactions: Users are responsible for any interactions, engagements, or transactions with third-party websites or content accessed through external links provided by AXN.ai.  The platform disclaims any liability for the outcomes of user interactions with external entities beyond its control.</p>
      <p class="disclaimer-content">Content Verification and Compliance:  While AXN.ai makes efforts to ensure the relevance and credibility of external links, users are encouraged to verify the accuracy, reliability, and legality of content accessed through external sources. AXN.ai disclaims any responsibility for the content and operations of external websites or platforms.</p>
      <p class="disclaimer-content">Data Privacy and Security: Users are urged to review the privacy policies and terms of use of external websites or platforms to understand their data practices and security measures. AXN.ai disclaims any liability for the data privacy and security practices of external entities.</p>
      <p class="disclaimer-content">Legal Compliance and Jurisdiction: Users' interactions with external links and third-party content are subject to compliance with the terms of use, policies, and regulations of the respective external entities. Any legal disputes related to external links fall under the jurisdiction of the relevant third-party websites or platforms.</p>
      <p class="disclaimer-content">Acknowledge and Acceptance: By accessing external links provided by AXN.ai, users expressly acknowledge and accept the provisions outlined in this external links disclaimer. They agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits in relation to their interactions with external entities to the fullest extent permitted by law.</p>
      <p class="disclaimer-content">External Sources: The AXN.AI website and mobile application may contain links to external websites or content originating from third parties, as well as links to websites and features in banners or other forms of advertising.</p>
      <p class="disclaimer-content">No Endorsement or Verification: We do not investigate, monitor, or verify the accuracy, adequacy, validity, reliability, availability, or completeness of any third-party content. AXN.AI does not endorse, guarantee, or assume responsibility for the information provided by third-party websites or for any products or services offered by third parties.</p>

      <h3 class="disclaimer-subheading">User Discretion</h3>
      <p class="disclaimer-content">Content Evaluation: Users are encouraged to exercise sound judgment and discretion when interacting with AI tools, third-party content, and external links provided by AXN.ai. It is essential for users to independently evaluate the relevance, accuracy, and legality of the content and information they access or generate within the platform.</p>
      <p class="disclaimer-content">Risk Awareness: Users should be aware of the inherent risks associated with interacting with AI-powered features and engaging with third-party content. While AXN.ai strives to provide a secure environment, users acknowledge that AI tools, external links, and third-party content may pose risks and limitations.</p>
      <p class="disclaimer-content">Privacy and Data Security: Users are advised to consider the privacy and data security implications of their interactions and engagements within AXN.ai. It is crucial for users to exercise discretion in sharing sensitive information and to review the platform's privacy and security measures.</p>
      <p class="disclaimer-content">Informed Engagement: Users should engage with the understanding that the platform's AI tools, external links, and third-party content serve as aids but do not replace individual judgment, professional advice, or ethical conduct. It is the user's responsibility to make informed decisions and seek professional guidance when necessary.</p>
      <p class="disclaimer-content">Acknowledgment and Acceptance: By utilizing AXN.ai's services, users explicitly acknowledge and accept the importance of exercising discretion and responsibility in their interactions with the platform's features and content. Users agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits in relation to their discretion-related decisions to the fullest extent permitted by law.</p>
      <p class="disclaimer-content">Compliance with Terms: Users are expected to comply with the terms of service, privacy policies, and community guidelines established by AXN.ai. This includes exercising discretion within the boundaries of ethical and legal standards and respecting the rights and privacy of others within the platform's community.</p>
      <p class="disclaimer-content">Caution Advised: Users are cautioned to use their own judgment and conduct due diligence before engaging with any external sites or third-party providers.</p>
      <p class="disclaimer-content">No Involvement in Third-Party Transactions: AXN.AI is not responsible for monitoring transactions or interactions between users and third-party providers of products or services.</p>

      <p class="disclaimer-content">This section emphasizes the importance of user discretion and responsibility when engaging with AXN.ai's AI-powered features, external content, and third-party interactions. It aims to ensure transparency and user understanding within the Disclaimer, while promoting ethical conduct and informed decision-making.</p>

      <h3 class="disclaimer-subheading">Professional Disclaimer</h3>
      <p class="disclaimer-content">Supportive Technology: AXN.ai's AI-powered services and tools, including Alice and Hannah, are designed to support and enhance professional activities such as meeting attendance, transcription, translation, and communication. However, they are not a substitute for professional judgment, advice, or expertise.</p>
      <p class="disclaimer-content">Professional Advice and Decision-Making: Users should be aware that while AXN.ai's services are valuable aids, they do not replace the need for individual professional judgment, legal, medical, financial, or other professional advice. Users are encouraged to seek professional expertise when making critical decisions.</p>
      <p class="disclaimer-content">User Evaluation and Application: Professionals utilizing AXN.ai's services bear the responsibility to evaluate, interpret, and apply the information provided by the platform's AI tools in accordance with their professional knowledge, expertise, and ethical standards specific to their field.</p>
      <p class="disclaimer-content">Non-Guarantee of Professional Outcomes: AXN.ai does not guarantee specific professional outcomes, results, or performance from the use of its AI-powered services. The platform acknowledges that individual professional judgment and expertise play a critical role in achieving professional objectives.</p>
      <p class="disclaimer-content">Indemnification and Non-Reliance: Users agree to indemnify and hold AXN.ai, its subsidiaries, affiliates, employees, and agents harmless from any claims, losses, damages, or liabilities arising from their reliance on the platform's services as a replacement for professional advice. AXN.ai disclaims any liability for the outcomes of professional decisions made based on the use of its services.</p>
      <p class="disclaimer-content">Legal Compliance and Acknowledgment: By utilizing AXN.ai's services for professional purposes, users explicitly acknowledge and accept the provisions outlined in this professional disclaimer. They agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits related to their professional use of the platform to the fullest extent permitted by law.</p>

      <p class="disclaimer-content">This comprehensive section aims to emphasize the supportive nature of AXN.ai's AI-powered services while highlighting the importance of professional expertise and judgment in decision-making. It aims to ensure transparency and user understanding within the Disclaimer, promoting ethical and informed professional conduct.</p>

      <h3 class="disclaimer-subheading">Limitation on Professional Advice</h3>
      <p class="disclaimer-content">AXN.ai aims to provide valuable AI-powered services, including media recording, transcription, translation, and communication tools such as Alice and Hannah. However, it's important to note the following limitations on professional advice when using our services:</p>
      <p class="disclaimer-content">Supportive Nature of Services: Users acknowledge that while AXN.ai's services may provide valuable support and assistance, they are not intended to replace professional judgment, advice, or expertise specific to fields such as legal, medical, financial, or other professional domains.</p>
      <p class="disclaimer-content">Encouragement of Professional Consultation: Users are strongly encouraged to seek professional expertise and advice tailored to their specific needs and circumstances when making significant decisions. Our services should be utilized as aids to professional judgment rather than substitutes for it.</p>
      <p class="disclaimer-content">User Evaluation and Application: Professionals utilizing AXN.ai's services assume the responsibility to evaluate, interpret, and apply the information provided within the context of their professional knowledge, expertise, and ethical standards within their respective fields.</p>
      <p class="disclaimer-content">No Guarantee of Professional Outcomes: AXN.ai does not guarantee specific professional outcomes, results, or performance from the use of its services. Users understand and acknowledge that individual professional judgment and expertise play a crucial role in achieving professional objectives.</p>
      <p class="disclaimer-content">Indemnification and Non-Reliance: Users agree to indemnify and ho ld AXN.ai, its subsidiaries, affiliates, employees, and agents harmless from any claims, losses, damages, or liabilities arising from their reliance on the platform's services as a replacement for professional advice. AXN.ai disclaims any liability for the outcomes of professional decisions made based on the use of its services.</p>
      <p class="disclaimer-content">Legal Compliance and Acknowledgment: By utilizing AXN.ai's services for professional purposes, users explicitly acknowledge and accept the limitations on professional advice outlined in this section. They agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits related to their professional use of the platform to the fullest extent permitted by law.</p>
      <p class="disclaimer-content">No Professional-Client Relationship: The use of AXN.AI’s services, including the implementation of any suggestions or the utilization of resources available through these services, does not establish a professional-client relationship between the user and AXN.AI or any of its professionals.</p>

      <p class="disclaimer-content">This detailed section underscores the supportive nature of AXN.ai's services and the crucial role of professional judgment and guidance in decision-making, ensuring transparency and legal compliance within the Terms of Service disclaimer.</p>

      <h3 class="disclaimer-subheading">User Responsibility and Caution</h3>
      <p class="disclaimer-content">Informed Decision-Making: Users are responsible for making informed decisions when utilizing AXN.ai's AI-powered services. It is crucial for users to evaluate the suitability of the services for their specific needs and to exercise caution when making decisions based on the information provided.</p>
      <p class="disclaimer-content">Accuracy of User Input: Users bear the responsibility for ensuring the accuracy, legality, and ethical nature of the input, content, and interactions they provide within AXN.ai's platform. It is essential to verify the integrity of the information shared and to exercise caution to avoid misrepresentation or infringement.</p>
      <p class="disclaimer-content">Data Privacy and Security: Users must prioritize the confidentiality and security of any sensitive information or data shared within AXN.ai's platform. It is imperative to be cautious when sharing personal or organizational data and to comply with data privacy regulations and guidelines.</p>
      <p class="disclaimer-content">Ethical Use of AI Tools: Users are expected to engage with AXN.ai's AI tools, such as Hannah, in an ethical manner, refraining from any abusive, discriminatory, or inappropriate behavior. Caution should be exercised to ensure respectful and responsible interactions.</p>
      <p class="disclaimer-content">Compliance with Policies and Laws: Users agree to comply with AXN.ai's terms of service, community guidelines, and all applicable laws and regulations. It is the user's responsibility to exercise caution and adhere to ethical and legal standards within the platform's community.</p>
      <p class="disclaimer-content">Indemnification and Non-Liability: By using AXN.ai's services, users explicitly acknowledge and accept their responsibility and the need for caution outlined in this section. They agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits related to their responsibilities and cautionary actions to the fullest extent permitted by law.</p>
      <p class="disclaimer-content">Independent Judgment: Users are encouraged to seek independent professional advice for their specific situations. Reliance on any information provided by AXN.AI is solely at the user's own risk.</p>
      <p class="disclaimer-content">No Liability for Decisions: AXN.AI accepts no liability for any loss or damage incurred as a result of using or relying on the information and services provided. This includes, but is not limited to, errors, omissions, or inaccuracies in the service content.</p>

      <p class="disclaimer-content">This comprehensive section emphasizes user responsibility, informed decision-making, and the need for caution when engaging with AXN.ai's AI-powered platform, ensuring transparency, legal compliance, and user understanding within the Disclaimer.</p>

      <h3 class="disclaimer-subheading">Transcription Accuracy Disclaimer</h3>
      <p class="disclaimer-content">Nature of Transcription Services</p>
      <p class="disclaimer-content">Method of Transcription: The transcription services provided by AXN.AI are based on automated processes and/or human input. While we strive for accuracy, these methods are inherently subject to potential errors and inaccuracies.</p>
      <p class="disclaimer-content">No Guarantee of Accuracy: AXN.AI does not guarantee the accuracy, completeness, or reliability of transcriptions provided. Errors may occur, and the information provided by our transcription service should be independently verified by users.</p>
      <p class="disclaimer-content">User Responsibility</p>
      <p class="disclaimer-content">Review and Verification: Users are responsible for reviewing and verifying the accuracy of transcriptions before using or relying on them for any decision-making or other purposes.</p>
      <p class="disclaimer-content">Limitation of Liability: AXN.AI shall not be liable for any inaccuracies or errors in the transcriptions, nor for any loss or damage incurred as a result of relying on these transcriptions.</p>

      <h3 class="disclaimer-subheading">Use at Your Own Risk Disclaimer</h3>
      <p class="disclaimer-content">Nature of Service and Risks</p>
      <p class="disclaimer-content">Understanding of AI-Powered Services: Users acknowledge and understand that the AI-powered services provided by AXN.ai, including media recording, transcription, translation, summarization, and communication with AI entities such as Hannah, are to be utilized at their own risk.</p>
      <p class="disclaimer-content">Limitations and Inherent Risks: Users recognize that AI technology, while designed to provide support and assistance, may have limitations and inherent risks. These risks may include the potential for inaccuracies, misinterpretations, or limitations in the scope of understanding user input.</p>
      <p class="disclaimer-content">Responsibility for Decisions and Actions: Users accept full responsibility for the decisions and actions they make based on the use of AXN.ai's services. They understand that the platform's AI tools and features serve as aids but do not replace individual judgment, professional advice, or ethical conduct.</p>
      <p class="disclaimer-content">Third-Party Interactions: Users acknowledge that any interactions with third-party content, external links, or external platforms facilitated through AXN.ai's services are at their own discretion and risk. The platform disclaims any liability for the outcomes of such interactions.</p>
      <p class="disclaimer-content">Data Privacy and Security Considerations: Users are responsible for evaluating and managing the privacy and security implications of their interactions within AXN.ai's platform, including the sharing of personal or sensitive information. They understand the importance of exercising discretion and due diligence in this regard.</p>
      <p class="disclaimer-content">Legal Compliance and Jurisdiction: Users' acceptance of the use at their own risk disclaimer signifies their acknowledgment and acceptance of the provisions outlined in this section. They agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits related to their use of the platform to the fullest extent permitted by law.</p>
      <p class="disclaimer-content">Review and Acceptance of Terms: By engaging with AXN.ai's services, users explicitly acknowledge and accept the use at your own risk disclaimer, understanding the potential limitations and risks associated with utilizing AI-powered services and features.</p>

      <h3 class="disclaimer-subheading">User Responsibility</h3>
      <p class="disclaimer-content">The use of AXN.AI’s services, including all content and functions available through our website and mobile application, is at the user's sole risk. Users are responsible for understanding the nature of the services and assessing their suitability for their needs.</p>
      <p class="disclaimer-content">Service Provided "as is": AXN.AI’s services are provided on an "AS IS" and "AS AVAILABLE" basis. This means that we do not represent or warrant to the reliability, effectiveness, or appropriateness of our services for any particular purpose.</p>

      <p class="disclaimer-content">This detailed section emphasizes the recognition of inherent risks and personal responsibilities associated with using AXN.ai's AI-powered services, aiming to provide legal protection while ensuring transparency and user understanding within the Terms of Service disclaimer.</p>

      <h3 class="disclaimer-subheading">Absence of Warranties</h3>
      <p class="disclaimer-content">No Implied Warranties: The service is offered without any warranties of any kind, either express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.</p>
      <p class="disclaimer-content">No Warranty of Uninterrupted Use: We do not warrant that the use of our service will be uninterrupted, timely, secure, or error-free, nor do we warrant that any information obtained through our service will be accurate or reliable.</p>
      <p class="disclaimer-content">Service Nature and Limitations: AXN.ai provides AI-enabled services for media recording, transcription, translation, and summarization, as well as communication tools such as Alice and Hannah. Users acknowledge and agree that the services are provided on an "as is" and "as available" basis, with all associated limitations and potential inaccuracies inherent in AI technology.</p>
      <p class="disclaimer-content">No Express or Implied Warranties: AXN.ai expressly disclaims all warranties, whether express, implied, statutory, or otherwise, including but not limited to any warranties of accuracy, reliability, completeness, merchantability, non-infringement, or fitness for a particular purpose.</p>
      <p class="disclaimer-content">Third-Party Content and Integration: The platform does not warrant the accuracy, reliability, or legality of any third-party content or integration accessed through AXN.ai's services. Users understand that any engagement with third-party content or platforms is at their own risk and discretion.</p>
      <p class="disclaimer-content">Professional and User Responsibilities: Users acknowledge their responsibility to independently evaluate, verify, and validate the suitability of the services and content provided by AXN.ai for their specific needs and professional requirements. Additionally, users bear the responsibility for the accuracy, legality, and ethical nature of their interactions within the platform.</p>
      <p class="disclaimer-content">No Guarantees of Service Continuity: AXN.ai does not warrant that the services will be uninterrupted, timely, secure, error-free, or free from any harmful components, including viruses or malware. Users understand and accept the inherent risks associated with the use of online services.</p>
      <p class="disclaimer-content">Legal Compliance and Acknowledgment: By using AXN.ai's services, users explicitly acknowledge and accept the absence of warranties as outlined in this section and agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits related to the absence of warranties to the fullest extent permitted by law.</p>
      <p class="disclaimer-content">This comprehensive section aims to clarify the absence of warranties associated with using AXN.ai's AI-powered services, while emphasizing user understanding and legal compliance within the Terms of Service disclaimer.</p>

      <h3 class="disclaimer-subheading">Limitation of Liability</h3>
      <p class="disclaimer-content">Service Limitations: AXN.ai strives to provide reliable and accurate AI-enabled services; however, it cannot guarantee the complete accuracy, reliability, or suitability of the services for every user's specific needs or circumstances. Users acknowledge and understand that the use of AI tools and services inherently involves certain limitations and potential inaccuracies.</p>
      <p class="disclaimer-content">Third-Party Content and Integration:  AXN.ai may provide access to or integrate with third-party content, platforms, or services. The platform is not liable for the accuracy, reliability, or legality of third-party content, and users interact with such content at their own risk.</p>
      <p class="disclaimer-content">Responsibility for User Input and Content: Users are solely responsible for the accuracy, legality, and appropriateness of the input, content, and interactions they provide within AXN.ai's platform. The platform is not liable for any user-generated content, interactions, or decisions made based on the use of AXN.ai's services.</p>
      <p class="disclaimer-content">No Guarantees of Outcomes: While AXN.ai strives to deliver high-quality AI-powered services, the platform does not guarantee specific outcomes, results, or performance from the use of its tools and features. Users acknowledge that AI tools provide support and assistance but do not replace individual judgment or professional advice.</p>
      <p class="disclaimer-content">Indemnification and Non-Liability: Users agree to indemnify and hold AXN.ai, its subsidiaries, affiliates, employees, and agents harmless from any claims, losses, damages, or liabilities arising from their use of the platform's services. AXN.ai assumes no liability for any indirect, incidental, consequential, or punitive damages related to the use of its services.</p>
      <p class="disclaimer-content">Legal Compliance and Jurisdiction:  Users' use of AXN.ai's services is subject to compliance with all applicable laws and regulations. Additionally, any legal disputes relating to the platform's services and these terms are subject to the jurisdiction of Delaware, US.</p>
      <p class="disclaimer-content">Review and Acceptance of Terms: By using AXN.ai's services, users expressly acknowledge and accept the limitations of liability outlined in these terms and agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits to the fullest extent permitted by law.</p>
      <p class="disclaimer-content">Exclusion of Liability: Under no circumstances will AXN.AI, its affiliates, or their respective officers, employees, agents, or licensors be liable for any direct, indirect, incidental, special, consequential, or punitive damages resulting from the use or inability to use our services.</p>
      <p class="disclaimer-content">Acknowledgment by Users: By using AXN.AI’s services, users acknowledge and agree that any material downloaded or otherwise obtained through the use of our services is done at their own discretion and risk, and they will be solely responsible for any damage to their computer system or loss of data that results from the download of such material.</p>

      <p class="disclaimer-content">This comprehensive section aims to clarify the limitations of liability associated with using AXN.ai's AI-powered services, aiming to provide legal protection while ensuring transparency and user understanding within the Terms of Service disclaimer. Always seek legal counsel to ensure proper integration and alignment of these terms within the overall disclaimer.</p>

      <h3 class="disclaimer-subheading">Contact Us</h3>
      <p class="disclaimer-content">For any questions or concerns regarding this disclaimer, please contact us at support@axn.ai</p>

      </Modal>
      {/* Privacy Policy Modal */}
      <Modal className="priv" isOpen={isPrivacyPolicyOpen} onClose={() => setPrivacyPolicyOpen(false)} >
                
        <h1 class="privacy-policy-heading">Privacy Policy</h1>
        <p class="privacy-policy-last-updated"><strong>Last updated:</strong> April 1, 2024</p>
        <p class="privacy-policy-introduction">At AXN.ai, based in Delaware, U.S., we respect the privacy of our users and are committed to protecting the personal information you may provide while interacting with our services, including our AI-enabled transcription and summarization platform, and our AI-assistant services via WhatsApp; Hanah. This Privacy Policy outlines how we collect, use, share your information, and the measures we take to ensure its protection.</p>

        <h2 class="section-heading">User Rights and Choices</h2>

        <h3 class="sub-section-heading">Data Access and Control:</h3>
        <p class="sub-section-content">Users have the right to access and control their personal data and interactions within AXN.ai's platform. This includes the ability to view, edit, or delete personal information, communication records, and media content uploaded to the platform.</p>

        <h3 class="sub-section-heading">Consent and Expressive Choices:</h3>
        <p class="sub-section-content">AXN.ai respects user autonomy and provides transparency in obtaining informed consent for data collection and use. Users are empowered to make expressive choices regarding the extent of data sharing, disclosure, and preferences for personalized service delivery.</p>

        <h3 class="sub-section-heading">Privacy Settings and Management:</h3>
        <p class="sub-section-content">The platform offers user-friendly privacy settings and management options, enabling users to customize data sharing and access permissions, control communication preferences with AI tools, and manage data retention and deletion.</p>

        <h3 class="sub-section-heading">Opt-Out and Unsubscribe Options:</h3>
        <p class="sub-section-content">Users have the freedom to opt out of specific data collection or communication features within AXN.ai's services. The platform respects user choices to unsubscribe from certain communications and data interactions, maintaining privacy and empowering user control.</p>

        <h3 class="sub-section-heading">Ethical Data Usage and Transparency:</h3>
        <p class="sub-section-content">AXN.ai operates with transparency and ethical data usage practices, honoring user choices and providing clear communication about the intended use of user data, interactions with AI tools, and personalized content delivery.</p>

        <h3 class="sub-section-heading">Compliance with User Preferences:</h3>
        <p class="sub-section-content">The platform is committed to respecting user preferences and choices, ensuring that data sharing, disclosure, and interactions align with individual consent and desires for personalized service delivery.</p>

        <h3 class="sub-section-heading">Legal Compliance and Accountability:</h3>
        <p class="sub-section-content">All user rights and choices within AXN.ai's services fully comply with applicable data protection laws and regulations, with a commitment to ethical and legal standards. The platform stands accountable for upholding user privacy rights and data protection requirements.</p>

        <h3 class="sub-section-heading">Acknowledgment and Consent:</h3>
        <p class="sub-section-content">By using AXN.ai's services, users acknowledge and accept the user rights and choices provisions outlined in the privacy policy. Users agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits related to user rights and choices to the fullest extent permitted by law.</p>

        <p class="privacy-policy-summary">This comprehensive write-up outlines the user rights and choices within AXN.ai's platform, providing legal protection, user empowerment, and ethical standards for data sharing, disclosure, and user interactions.</p>

        <h2 class="section-heading">Data Security and Retention</h2>

        <h3 class="sub-section-heading">Comprehensive Data Security Measures:</h3>
        <p class="sub-section-content">AXN.ai is dedicated to upholding the highest standards of data security, employing robust encryption, access controls, and secure storage protocols to safeguard user data from unauthorized access, breaches, and cyber threats.</p>

        <h3 class="sub-section-heading">Confidentiality and Integrity:</h3>
        <p class="sub-section-content">User data, including personal details, communication records, and media content, is treated with the utmost confidentiality and integrity, ensuring its protection and preventing any unauthorized modifications or access.</p>

        <h3 class="sub-section-heading">Minimized Data Retention:</h3>
        <p class="sub-section-content">AXN.ai adheres to stringent data retention policies, ensuring that user data is retained only for the necessary duration to fulfill the purposes for which it was collected. Once the data is no longer required, it is securely and permanently deleted in accordance with legal and regulatory requirements.</p>

        <h3 class="sub-section-heading">Limitations on Access:</h3>
        <p class="sub-section-content">Access to user data is restricted to authorized personnel on a need-to-know basis, with rigorous access controls and monitoring mechanisms in place to prevent unauthorized use, disclosure, or modification of user information.</p>

        <h3 class="sub-section-heading">Data Breach Response and Notification:</h3>
        <p class="sub-section-content">In the event of a data breach, AXN.ai is committed to promptly assessing, mitigating, and resolving the breach, and if necessary, providing timely and transparent notification to affected users, authorities, and relevant stakeholders.</p>

        <h3 class="sub-section-heading">Legal Compliance and Accountability:</h3>
        <p class="sub-section-content">All data security and retention practices within AXN.ai fully comply with applicable data protection laws and regulations, with a commitment to ethical and legal standards. The platform stands accountable for upholding user privacy rights and data protection requirements.</p>

        <h3 class="sub-section-heading">User Access and Control:</h3>
        <p class="sub-section-content">Users are empowered to access, review, and request the deletion of their personal data, maintaining control over their information and ensuring compliance with their privacy preferences and rights.</p>

        <h3 class="sub-section-heading">Acknowledgment and Consent:</h3>
        <p class="sub-section-content">By utilizing AXN.ai's services, users acknowledge and accept the data security and retention provisions outlined in the privacy policy. Users agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits related to data security and retention to the fullest extent permitted by law.</p>

        <h3 class="sub-section-heading">Security Measures:</h3>
        <p class="sub-section-content">AXN.AI employs robust security measures to protect personal information from unauthorized access, alteration, disclosure, or destruction.</p>

        <h3 class="sub-section-heading">Retention Policy:</h3>
        <p class="sub-section-content">Personal information is retained only for as long as necessary to fulfill the purposes for which it was collected, in compliance with our data retention policies and applicable laws.</p>

        <h2 class="section-heading">Policy Updates and Notifications</h2>

        <h3 class="sub-section-heading">Amendments:</h3>
        <p class="sub-section-content">This Privacy Policy may be updated periodically to reflect changes in our practices or regulatory requirements. Users will be notified of significant changes through our services or via email.</p>

        <h3 class="sub-section-heading">Effective Date and Acknowledgement:</h3>
        <p class="sub-section-content">The effective date of the policy will be clearly stated, and continued use of the service after any changes will constitute acceptance of the new terms.</p>

        <h2 class="section-heading">Payment and Subscription</h2>

        <h3 class="sub-section-heading">Fees and Payment Terms</h3>

        <h4 class="sub-sub-section-heading">Service Fees:</h4>
        <p class="sub-sub-section-content">Certain features of AXN.AI’s services may require payment of fees. The specific fees and payment terms, including any applicable taxes, will be detailed at the time of purchase or subscription.</p>

        <h4 class="sub-sub-section-heading">Payment Processing:</h4>
        <p class="sub-sub-section-content">Payments are processed through secure, third-party payment gateways. Users are responsible for providing accurate and valid payment information.</p>

        <h3 class="sub-section-heading">Subscription Services</h3>

        <h4 class="sub-sub-section-heading">Subscription Agreement:</h4>
        <p class="sub-sub-section-content">By subscribing to AXN.AI services, users agree to pay the recurring subscription fees as specified during the subscription process.</p>

        <h4 class="sub-sub-section-heading">Renewal and Billing:</h4>
        <p class="sub-sub-section-content">Subscriptions are automatically renewed at the end of each billing cycle, unless cancelled by the user. Users will be billed in accordance with the billing terms disclosed at the time of subscription.</p>

        <h2 class="section-heading">Cancellation and Refunds</h2>

        <p class="section-summary">This detailed section addresses the cancellation and refund policy for outstanding credits within AXN.ai's services, aiming to provide clarity, fairness, and legal compliance while protecting the company from potential obligations, liabilities, and disputes. Always seek legal advice to review and finalize the terms for legal compliance.</p>

        <h3 class="sub-section-heading">Cancellation Policy:</h3>
        <p class="sub-section-content">Users may cancel their subscription at any time. The cancellation will take effect at the end of the current billing cycle.</p>

        <h3 class="sub-section-heading">Refund for Outstanding Credits:</h3>
        <p class="sub-section-content">AXN.ai generally does not provide refunds for outstanding credits available to users. Credits are non-transferable and non-refundable within the platform's service offerings.</p>

        <h3 class="sub-section-heading">Exceptional Circumstances:</h3>
        <p class="sub-section-content">In exceptional circumstances, AXN.ai may consider refund requests for outstanding credits. Users are encouraged to reach out to customer support with compelling reasons for the refund request.</p>

        <h3 class="sub-section-heading">Transparency in Refund Review:</h3>
        <p class="sub-section-content">Refund requests for outstanding credits will be reviewed with transparency and fairness, considering the individual circumstances and policy limitations.</p>

        <h3 class="sub-section-heading">User Responsibility:</h3>
        <p class="sub-section-content">Users are responsible for managing their credits and are encouraged to utilize them effectively within the platform's services. Utilization of credits does not guarantee eligibility for a refund.</p>

        <h3 class="sub-section-heading">Discontinuation of Services:</h3>
        <p class="sub-section-content">In the event of service discontinuation or suspension, AXN.ai will provide information regarding any applicable refunds or compensation owed to users.</p>

        <h3 class="sub-section-heading">Legal Compliance and Accountability:</h3>
        <p class="sub-section-content">The cancellation and refund policy complies with all applicable laws and regulations concerning user rights and reimbursement privileges.</p>

        <h3 class="sub-section-heading">Exceptional Circumstances Review:</h3>
        <p class="sub-section-content">In instances where exceptional circumstances warrant a refund, AXN.ai commits to conducting a fair and thorough review process to address user concerns.</p>

        <h3 class="sub-section-heading">Effective Communication:</h3>
        <p class="sub-section-content">Users are encouraged to communicate with AXN.ai's customer support for any cancellation or refund-related queries, ensuring clear and effective dialogue.</p>

        <h2 class="section-heading">Changes to Fees and Services</h2>

        <h3 class="sub-section-heading">Modification Rights:</h3>
        <p class="sub-section-content">AXN.AI reserves the right to modify the subscription fees and the services offered at any time. Such changes will be communicated to users in advance.</p>

        <h3 class="sub-section-heading">Acceptance of Changes:</h3>
        <p class="sub-section-content">Continued use of AXN.AI’s services after any changes to fees or services constitutes acceptance of the new terms.</p>

        <h2 class="section-heading">Termination</h2>

        <h3 class="sub-section-heading">Termination by AXN.AI</h3>

        <h4 class="sub-sub-section-heading">Rights of Termination:</h4>
        <p class="sub-sub-section-content">AXN.AI reserves the right to terminate or suspend a user's access to the services immediately, without prior notice, in the event of a breach of these Terms, or if the user engages in conduct harmful to AXN.AI, its services, or other users.</p>

        <h4 class="sub-sub-section-heading">Consequences of Termination:</h4>
        <p class="sub-sub-section-content">Upon termination, the user's right to access and use the services will cease immediately. AXN.AI is not liable for any damages or loss resulting from termination.</p>

        <h3 class="sub-section-heading">Termination by User</h3>

        <h4 class="sub-sub-section-heading">User Initiated Termination:</h4>
        <p class="sub-sub-section-content">Users may terminate their use of AXN.AI’s services at any time by closing their account through the account settings. Such termination will take effect immediately.</p>

        <h4 class="sub-sub-section-heading">Effect of Account Closure:</h4>
        <p class="sub-sub-section-content">Upon closure of an account, the user's access to the services will be terminated, and any remaining credits or subscriptions will be forfeited.</p>

        <h3 class="sub-section-heading">Survival of Terms:</h3>
        <p class="sub-section-content">Sections concerning rights, responsibilities, limitations of liability, and dispute resolution will survive termination and continue to apply even after termination.</p>

        <h2 class="section-heading">Governing Law and Dispute Resolution</h2>

        <h3 class="sub-section-heading">Governing Law:</h3>
        <p class="sub-section-content">These Terms shall be governed by and construed in accordance with the laws of the State of Delaware, United States, without regard to its conflict of law provisions.</p>

        <h3 class="sub-section-heading">Dispute Resolution:</h3>
        <p class="sub-section-content">Any dispute arising out of or relating to these Terms or the services provided by AXN.AI shall be resolved through binding arbitration conducted by the American Arbitration Association (AAA) in accordance with its Commercial Arbitration Rules.</p>

        <h3 class="sub-section-heading">Arbitration Process:</h3>
        <p class="sub-section-content">The arbitration shall take place in Wilmington, Delaware, and the arbitrator's decision shall be final and binding. Each party shall bear its own costs and expenses, including attorney's fees, associated with the arbitration.</p>

        <h3 class="sub-section-heading">Class Action Waiver:</h3>
        <p class="sub-section-content">Both parties waive the right to bring any dispute as a class, consolidated, representative, collective, or private attorney general action, or to participate in a class, consolidated, representative, collective, or private attorney general action regarding any dispute.</p>

        <h2 class="section-heading">Contact Us</h2>

        <p class="sub-section-content">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at privacy@axn.ai.</p>

        </Modal>

{/* Terms & Conditions Modal */}
<Modal className="terms-conditions-modal " isOpen={isTermsOpen} onClose={() => setTermsOpen(false)} type="terms-conditions-modal">
  <h1 class="privacy-policy-heading">Terms & Conditions</h1>
    <h2 class="terms-heading">Introduction</h2>
      <p class="terms-content">Welcome to AXN.AI, your go-to solution for media transcription and interactive chat services. By using our website, applications, and services, you agree to adhere to these terms and conditions. Important aspects covered include age restrictions, subscription details, and user conduct. If you're not in agreement with any of these terms, we advise against using our services.</p>

      <h2 class="terms-heading">Service Description</h2>
      <p class="terms-content">AXN.AI ushers in a new era of digital engagement by transforming traditional file interaction into dynamic, conversational experiences. Introducing AXN.ai, your all in one AI-powered solution where you get seamless media transcription, summarization, translation (over 100 languages), and much more. Our services include, but are not limited to, the following features:</p>

      <ul class="terms-feature-list">
          <li class="terms-feature-item">Interactive File Engagement: Users can engage with various digital file formats, including but not limited to, videos, music files, audio files, Word documents, and PDFs, in an interactive manner. This interaction transforms static files into dynamic entities that communicate information in a novel and user-friendly way.</li>
          <li class="terms-feature-item">Data Interpretation and Presentation: AXN.AI facilitates an advanced interpretation of data, enabling users to perceive underlying stories and insights. This feature is designed to enhance user comprehension and engagement with otherwise complex data sets.</li>
          <li class="terms-feature-item">Enhanced Digital Document Interaction: The platform endows digital documents with a level of interactivity that elevates user experience, moving beyond mere simplification to create an enriching and enjoyable interaction with digital content.</li>
          <li class="terms-feature-item">Personalized User Experience: Each interaction with the platform is tailored to provide a unique and engaging experience, with a focus on revealing the intrinsic personality and narrative within each file.</li>
      </ul>

      <p class="terms-content">AXN.AI is committed to continually evolving and expanding its services to deliver innovative digital solutions. Users are encouraged to explore the full potential of our platform, keeping in mind that the scope of services may be updated periodically, as detailed in our updates and communications.</p>

      <h2 class="terms-heading">AXN.ai Products</h2>

      <p class="terms-content">AXN.ai offers a comprehensive suite of AI-powered services designed to enhance productivity, communication, and content management for users across diverse industries and demographics. Our platform leverages advanced artificial intelligence to deliver the following key features and services:</p>

      <ul class="terms-feature-list">
          <li class="terms-feature-item">AI-Enabled Media Recording and Transcription: AXN.ai provides seamless audio and video recording capabilities, along with precise transcription of content in multiple languages, empowering users to capture, document, and analyze critical communication.</li>
          <li class="terms-feature-item">Translation and Summarization Services: With AXN.ai, users can access advanced translation and summarization tools that facilitate the conversion of content across various languages and formats, fostering global communication and content dissemination.</li>
          <li class="terms-feature-item">AI Digital Meeting Assistant - "Alice": Utilizing the power of AI, "Alice" serves as an AI-enabled digital meeting assistant, capable of attending, recording, transcribing, summarizing, and translating meetings in real time, facilitating efficient collaboration and knowledge retention.</li>
          <li class="terms-feature-item">WhatsApp Integrated AI Companion - "Hannah": The innovative "Hannah" feature integrates seamlessly with WhatsApp, offering multi-language communication via text and voice notes. Users can leverage "Hannah" as an AI-powered everyday companion for queries, information, and support.</li>
          <li class="terms-feature-item">Media Upload and Manipulation: AXN.ai's media upload feature supports various file formats, including audio, text, Word, PDF, and Excel. Users can avail themselves of translation, transcription, summarization, and interactive communication with their media transcriptions.</li>
          <li class="terms-feature-item">YouTube Video Transcription and Interaction: Users have the option to upload and process YouTube videos via AXN.ai, enabling transcription, summarization, and translation of content. Additionally, users can engage in interactive Q&A sessions related to specific topics within the video's content.</li>
      </ul>

      <p class="terms-content">AXN.ai is committed to accessibility and inclusivity, ensuring that its services cater to individuals of all backgrounds, professions, and abilities. By integrating cutting-edge AI technologies, AXN.ai strives to revolutionize how users engage with media, communication, and collaboration, transcending linguistic, cultural, and geographical barriers.</p>

      <p class="terms-content">This detailed content outlines the unique features and services offered by AXN.ai, ensuring clarity and transparency for users accessing the platform.</p>

      <h2 class="terms-heading">User Account</h2>

      <h3 class="terms-subheading">Registration and Account Creation</h3>
      <p class="terms-content">Mandatory Registration: To access the full range of AXN.AI's interactive digital experiences, users are required to register and create an account. This process involves providing accurate and current information as prompted during the registration process.</p>
      <p class="terms-content">Account Verification: Depending on the nature of services chosen, additional verification processes may be required to ensure the authenticity and security of user accounts.</p>

      <h3 class="terms-subheading">Age Limitation and Eligibility</h3>
      <p class="terms-content">Minimum Age Requirement: The services of AXN.AI are intended for users who are 13 years of age or older. By creating an account, users affirm that they meet the minimum age requirement.</p>
      <p class="terms-content">Eligibility Verification: AXN.AI reserves the right to request proof of age and to refuse service or terminate accounts if users do not meet the specified age criteria.</p>

      <h3 class="terms-subheading">User Representation & Age Requirements</h3>
      <p class="terms-content">When setting the minimum age limit for users of AXN.ai, it's crucial to consider legal and privacy implications, especially in accordance with relevant regulations such as the Children's Online Privacy Protection Act (COPPA) in the United States.</p>
      <ul class="terms-list">
          <li class="terms-item">Minimum Age Limit: AXN.ai requires users to be at least 13 years of age to access the platform and utilize its services. This aligns with COPPA's age threshold for obtaining verifiable parental consent.</li>
          <li class="terms-item">User Representation: Upon registering or accessing AXN.ai, users affirm that they are at least 13 years old and have obtained any required parental consent if they are under the age of 18.</li>
          <li class="terms-item">Parental Consent: For users under the age of 18, parental consent is required to access certain features or engage in specific interactions on the platform, especially those involving personal data or communication with AI entities like Hannah.</li>
          <li class="terms-item">Data Privacy and Child Protection: AXN.ai follows stringent data privacy and child protection practices to safeguard the online experience of users under the age of 18, ensuring that their interactions comply with legal requirements and ethical standards.</li>
          <li class="terms-item">Legal Acknowledgment: By using AXN.ai, users acknowledge that the platform's minimum age limit and parental consent requirements are in place to comply with relevant laws and protect the privacy and well-being of minors.</li>
          <li class="terms-item">Compliance Assurance: AXN.ai reserves the right to restrict or terminate access to services for users who do not meet the minimum age requirements or violate the platform's age-related policies.</li>
      </ul>

      <h3 class="terms-subheading">Account Responsibility and Security</h3>
      <p class="terms-content">Confidentiality Obligation: Users are solely responsible for maintaining the confidentiality of their account information, including password security. AXN.AI shall not be liable for any loss or damage arising from the user’s failure to comply with this security obligation.</p>
      <p class="terms-content">Notification of Unauthorized Use: Users must promptly notify AXN.AI of any unauthorized use or suspected compromise of their account.</p>

      <h3 class="terms-subheading">User Responsibilities</h3>
      <p class="terms-content">Accuracy of Information: Users are responsible for providing accurate and up-to-date information both at the time of registration and throughout the duration of their use of AXN.AI’s services.</p>
      <p class="terms-content">Legal Compliance: Users must use the service in compliance with all applicable local, state, national, and international laws and regulations.</p>

      <h3 class="terms-subheading">Account Features</h3>
      <p class="terms-content">Interactive Experience Customization: Each user account is designed to adapt and respond to individual user interactions, providing a personalized and engaging experience with digital files.</p>
      <p class="terms-content">Access to Enhanced Features: Registered users gain access to advanced features, including but not limited to interactive data interpretation, personalized content narratives, and other unique functionalities of AXN.AI.</p>

      <h3 class="terms-subheading">Account Termination and Suspension</h3>
      <p class="terms-content">Informed Decision-Making: Users are responsible for making informed decisions when utilizing AXN.ai's AI-powered services. It is crucial for users to evaluate the suitability of the services for their specific needs and to exercise caution when making decisions based on the information provided.</p>
      <p class="terms-content">*Voluntary Account Termination: Users have the right to voluntarily terminate their AXN.ai accounts at any time. Upon request for account termination, all user data and personal information will be securely deleted from our systems, subject to any legal or regulatory requirements for data retention.</p>
      <p class="terms-content">Suspension of Accounts: AXN.ai reserves the right to suspend user accounts in cases where there is a suspected violation of the platform's terms of service, community guidelines, or applicable laws and regulations. Account suspension may result from activities such as misuse of the platform, fraudulent behavior, or engagement in prohibited activities.</p>
      <p class="terms-content">Notification of Suspension: In the event of an account suspension, AXN.ai will provide users with a notification outlining the reason for the suspension, the duration (if temporary), and any necessary steps to address the issue. Users will have the opportunity to appeal the suspension and provide relevant information in their defense.</p>
      <p class="terms-content">Data Preservation during Suspension: During the suspension period, AXN.ai will take appropriate measures to preserve the user's data and information, ensuring its security and integrity. Users will not have access to the platform's services during the suspension period.</p>
      <p class="terms-content">User Initiated termination: Users can terminate their account at any time through the account settings.</p>
      <p class="terms-content">AXN.AI Initiated suspension and/or termination: AXN.AI reserves the right to suspend or terminate accounts at its discretion for any breach of these Terms or for activities harmful to the service or other users.</p>

      <h2 class="terms-heading">Content and Conduct</h2>

      <h3 class="terms-subheading">User-Generated Content</h3>
      <p class="terms-content">Content Ownership and Responsibility: Users retain ownership of the content they generate and are solely responsible for its legality, reliability, and appropriateness. By submitting content to AXN.AI, users grant AXN.AI a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and display this content in connection with operating and providing the services.</p>
      <p class="terms-content">Compliance with Laws: User-generated content must comply with all applicable local, state, national, and international laws and regulations, including but not limited to copyright and privacy laws.</p>

      <h3 class="terms-subheading">Prohibited Use</h3>
      <p class="terms-content">AXN.ai is committed to maintaining a safe, secure, and inclusive environment for all users. As such, the following activities and use cases are strictly prohibited on the platform:</p>
      <ul class="terms-list">
          <li class="terms-item">Unauthorized Access and Usage: Users are prohibited from engaging in any unauthorized access, usage, or tampering with AXN.ai's services, data, or infrastructure, including attempting to bypass security measures or manipulate content.</li>
          <li class="terms-item">Malicious Behavior: Any form of malicious behavior, including hacking, phishing, malware distribution, or attempts to disrupt the integrity and functionality of AXN.ai's platform, is strictly prohibited.</li>
          <li class="terms-item">Illegal Content and Activities: Users must refrain from uploading, sharing, or engaging in any illegal, fraudulent, or deceptive activities via AXN.ai, including the distribution of prohibited or offensive content.</li>
          <li class="terms-item">Violation of Privacy and Data Protection: AXN.ai strictly prohibits any unauthorized access, collection, or misuse of user data, as well as any other activities that violate privacy laws and regulations.</li>
          <li class="terms-item">Abuse of AI Features: Users must not abuse or misuse AI-enabled features, such as Alice and Hannah, for deceptive, offensive, or malicious purposes, including the dissemination of misinformation or inappropriate communication.</li>
          <li class="terms-item">Excessive & Unauthorized Data Manipulation: Manipulation or alteration of content, data, or transcripts, beyond the scope of authorized usage, is prohibited, ensuring the integrity and accuracy of AXN.ai's services.</li>
          <li class="terms-item">Violation of User Rights: Any activities that infringe upon the rights, privacy, or well-being of AXN.ai users, including harassment, discrimination, or inappropriate use of user-provided content, are strictly prohibited.</li>
          <li class="terms-item">Unlawful Activities: Users are prohibited from using AXN.AI services for any unlawful purposes, including but not limited to, infringement of intellectual property rights, fraud, and activities that violate privacy or publicity rights.</li>
          <li class="terms-item">Disruptive Conduct: Engaging in conduct that interrupts, damages, or impairs the functionality of the service, including the dissemination of viruses, malicious code, or other harmful technology, is strictly prohibited.</li>
          <li class="terms-item">Harassment and Abuse: Users must not engage in behavior that is harassing, threatening, abusive, or otherwise harmful to other users or to AXN.AI staff.</li>
      </ul>
      <p class="terms-content">Users engaging in any prohibited use as outlined above are subject to immediate account suspension or termination, as well as potential legal action and reporting to relevant authorities as deemed necessary. It is imperative for all users to adhere to these prohibitions to ensure the ethical, safe, and productive use of AXN.ai's services for the benefit of the entire user community.</p>

      <h3 class="terms-subheading">Enforcement and Consequences</h3>
      <p class="terms-content">Monitoring and Enforcement: AXN.AI reserves the right, but is not obligated, to monitor content and conduct on its platform. AXN.AI may take appropriate action, including removal of content and account suspension or termination, in response to violations of these terms.</p>
      <p class="terms-content">Reporting Violations: Users are encouraged to report any violations of these terms to AXN.AI for prompt action.</p>

      <h3 class="terms-subheading">Content Interaction Features</h3>
      <p class="terms-content">Innovative Engagement: In line with AXN.AI’s commitment to transforming digital interactions, users may experience enhanced and interactive ways of engaging with their own and others' content, subject to the terms outlined herein.</p>
      <p class="terms-content">Feedback and Improvements: User feedback on these interactive features is welcomed and may be used to improve the services offered by AXN.AI.</p>

      <h3 class="terms-subheading">Intellectual Property</h3>
      <p class="terms-content">Ownership of Service Material</p>
      <p class="terms-content">Ownership and Licensing: All content, features, and services provided by AXN.ai are protected by intellectual property laws, including but not limited to copyright, trademark, and trade secret laws. AXN.ai retains ownership of all proprietary technology, algorithms, software, and content used in its services. - Users are granted a limited, non-exclusive, non-transferable license to access and use AXN.ai's services for personal or internal business purposes, subject to compliance with the terms and conditions outlined in this agreement.</p>
      <p class="terms-content">User-Generated Content: Users are prohibited from uploading, sharing, or modifying content that infringes upon the intellectual property rights of AXN.ai, its users, or any third party. This includes copyrighted material, trademarks, trade secrets, and proprietary information.</p>
      <p class="terms-content">End-User Compliance: By accessing and utilizing AXN.ai's services, users agree to respect intellectual property rights, refrain from unauthorized use of content, and comply with all applicable laws and regulations pertaining to intellectual property.</p>
      <p class="terms-content">Intellectual Property Infringement Reporting: AXN.ai respects the intellectual property rights of others and encourages users to report any suspected infringement of their rights. Any notices of alleged infringement should be submitted in accordance with the procedures outlined in the terms of service.</p>
      <p class="terms-content">Limitation of Use and Reproduction: Users are prohibited from reproducing, modifying, distributing, or creating derivative works from AXN.ai's proprietary technology, algorithms, software, and content without explicit consent from AXN.ai.</p>
      <p class="terms-content">Reservation of Rights: AXN.ai reserves the right to take appropriate legal action in response to any unauthorized use, reproduction, distribution, or modification of its intellectual property, as well as the right to terminate access to services for users who violate intellectual property rights.</p>
      <p class="terms-content">Proprietary Rights: All materials related to the services provided by AXN.AI, including but not limited to software, text, images, graphics, logos, and trademarks, are the exclusive property of AXN.AI or its licensors. These materials are protected under intellectual property laws both domestically and internationally.</p>
      <p class="terms-content">No Transfer of Rights: Usage of AXN.ai services does not constitute a transfer or granting of any ownership rights in the service materials to users.</p>

      <h2 class="terms-heading">Use of Service Materials</h2>

      <h3 class="terms-subheading">Permissible Use</h3>
      <p class="terms-content">Users are granted a limited, revocable, non-exclusive, non-transferable license to access and utilize the services and materials provided by AXN.ai for personal or internal business purposes.</p>
      <p class="terms-content">Content Ownership and Restrictions: All proprietary technology, algorithms, and software used in the services, including AI-enabled tools such as Alice and Hannah, are the exclusive property of AXN.ai and its licensors. Users must not reproduce, modify, distribute, or create derivative works from the service materials without explicit consent.</p>
      <p class="terms-content">User-Generated Content: By uploading or providing content to AXN.ai, users retain ownership of their respective intellectual property rights. However, users grant AXN.ai a worldwide, royalty-free, non-exclusive license to use, distribute, and modify the provided content for the purpose of delivering the requested services.</p>
      <p class="terms-content">Interactive Communication: Users engaging in interactive communication with AI entities such as Hannah through voice notes, text, or uploaded media acknowledge that the provided responses and interactions are generated by AI technology and may not always reflect human judgment or emotion.</p>
      <p class="terms-content">Data Privacy and Confidentiality: AXN.ai respects the privacy and confidentiality of user-generated content and ensures that all interactions adhere to strict data protection protocols, safeguarding the proprietary nature of provided materials.</p>
      <p class="terms-content">Intellectual Property Reporting: Users are encouraged to report any suspected infringement of their intellectual property rights or unauthorized use of their materials by other users, enabling AXN.ai to take appropriate action to address the reported concerns.</p>
      <p class="terms-content">Compliance Assurance: Users must comply with all applicable laws and regulations pertaining to the use, transmission, and storage of service materials, including but not limited to intellectual property rights, data privacy, and confidentiality.</p>
      <p class="terms-content">Restricted Use: Users are permitted to use the service materials solely for personal and non-commercial purposes in connection with the services provided by AXN.AI. Any other use, including reproduction, modification, distribution, transmission, or display of service materials, is strictly prohibited without the express written consent of AXN.AI.</p>
      <p class="terms-content">Intellectual Property Compliance: Users must respect the intellectual property rights of AXN.AI and third parties at all times. Unauthorized use of service materials may result in civil and criminal penalties.</p>

      <h3 class="terms-subheading">User Content and Intellectual Property</h3>
      <p class="terms-content">Ownership of User-Generated Content: Users retain full ownership and responsibility for any content, media, or materials they upload, transmit, or generate while utilizing AXN.ai's services, including but not limited to audio files, transcripts, and media uploads.</p>
      <p class="terms-content">License Grant to AXN.ai: By uploading or providing content to AXN.ai, users grant the platform a non-exclusive, worldwide, royalty-free license to use, distribute, modify, and process the user-generated content for the purpose of delivering the requested services. This license facilitates the functionalities of media transcription, translation, and interactive communication, ensuring the effective delivery of AXN.ai's AI-enabled tools such as Alice and Hannah.</p>
      <p class="terms-content">Respect for Intellectual Property Rights: Users are required to respect the intellectual property rights of others, refrain from uploading, sharing, or modifying content that infringes upon copyright, trademark, or proprietary rights, and comply with all applicable laws and regulations.</p>
      <p class="terms-content">Data Privacy and Confidentiality: AXN.ai respects the privacy and confidentiality of user-generated content, implementing robust data protection and security measures to safeguard the materials and interactions between users and the platform's AI-enabled features.</p>
      <p class="terms-content">Intellectual Property Reporting and Compliance: AXN.ai encourages users to report any suspected infringement of their intellectual property rights, unauthorized use of their content, or violations related to intellectual property and content management. The platform is committed to addressing reported concerns and ensuring compliance with relevant laws and regulations.</p>
      <p class="terms-content">Limitation of Use and Reproduction: Users are prohibited from reproducing, modifying, distributing, or creating derivative works from AXN.ai's proprietary technology, algorithms, software, and content without explicit consent, ensuring the protection and integrity of the platform's intellectual property.</p>
      <p class="terms-content">User Content Rights: Users retain all rights to the intellectual property they own and submit to the AXN.AI platform. However, by submitting content, users grant AXN.AI a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and display the content in relation to the provided services.</p>
      <p class="terms-content">Respect for Third-Party Rights: Users must ensure that any content they submit does not infringe upon the intellectual property rights of third parties.</p>

      <h2 class="terms-heading">Intellectual Property Inquiries and Violations</h2>

      <h3 class="terms-subheading">Reporting Infringements</h3>
      <p class="terms-content">Users are encouraged to report any suspected infringements of intellectual property rights to AXN.AI for appropriate action.</p>

      <h3 class="terms-subheading">Response to Infringements</h3>
      <p class="terms-content">AXN.AI reserves the right to remove any content that is alleged to be infringing and may terminate the accounts of repeat infringers in accordance with applicable law.</p>

      <h3 class="terms-subheading">Respect for Intellectual Property Rights</h3>
      <p class="terms-content">AXN.ai is dedicated to upholding the intellectual property rights of all individuals and entities, including copyrights, trademarks, patents, and proprietary information. Users are expected to adhere to all relevant intellectual property laws and regulations when using our services.</p>

      <h3 class="terms-subheading">Inquiry Process for Intellectual Property Concerns</h3>
      <p class="terms-content">In circumstances where inquiries, concerns, or notices regarding potential intellectual property violations arise, individuals or entities asserting rights are encouraged to contact AXN.ai's designated intellectual property representative. Contact details for the representative will be made available upon request.</p>

      <h3 class="terms-subheading">Prompt Review and Resolution</h3>
      <p class="terms-content">Upon receipt of a legitimate inquiry or notice regarding intellectual property violations, AXN.ai will swiftly review the claim and take necessary action, which may involve removing or limiting access to the purportedly infringing material within a reasonable timeframe.</p>

      <h3 class="terms-subheading">User Cooperation and Disclosure</h3>
      <p class="terms-content">Users subject to intellectual property inquiries or claims are expected to collaborate with AXN.ai by providing pertinent information, evidence, or justifications to address the concerns. Users may be required to disclose the source, ownership, or rights related to the content in question.</p>

      <h2 class="terms-heading">Contact Information</h2>
      <p class="terms-content">For any questions about these terms, please contact us at support@AXN.ai</p>

      <h2 class="terms-heading">Effective Date</h2>
      <p class="terms-content">These terms are effective as of 8 February 2024.</p>
</Modal>


    </section>
  );
}

export default Footer;
