import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Testimonials.css'; // Ensure you have your CSS styles here

const Testimonials = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1, // Shows one slide at a time
        slidesToScroll: 1, // Scrolls one slide at a time
        autoplay: true,
    };

    return (
        <section className="testimonials">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <Slider {...settings}>
                            {/* TESTIMONIAL 1 */}
                            <div className="testimonial-item">
                                <div className="shadow-effect">
                                    <img className="img-circle" src="assets/images/tbg_2.png" alt="" />
                                    <p>
                                        "AXN.AI has revolutionized my workflow! Alice effortlessly attends and helps manage my meetings, saving me valuable time. Highly recommended!"
                                    </p>
                                </div>
                                <div className="testimonial-name">SARAH J. <span className="profession">Profession: Project manager</span></div>
                            </div>
                            {/* END OF TESTIMONIAL 1 */}

                            {/* Testimonial 2 */}
                            <div className="testimonial-item">
                                <div className="shadow-effect">
                                    <img className="img-circle" src="assets/images/tbg_1.png" alt="" />
                                    <p>
                                        "I kid you not, AXN.ai is next level! Alice and Hannah are like my virtual superheroes. Alice breezes through my lecture like a total pro, and Hannah on WhatsApp is like having a buddy who knows everything. And the media upload feature? Total lifesaver. Seriously, 5 stars for making life so much easier!"
                                    </p>
                                </div>
                                <div className="testimonial-name">JAMIE B. <span className="profession">Profession: Student</span></div>
                            </div>

                            {/* Testimonial 3 */}
                            <div className="testimonial-item">
                                <div className="shadow-effect">
                                    <img className="img-circle" src="assets/images/tbg_1.png" alt="" />
                                    <p>
                                        "I'm not one to hype things up, but AXN.ai is the real deal! Hannah is a lifesaver for quick research. Plus, the PDF/Word upload feature makes working with multiple documents very easy and time saving. 5 stars for making my workdays way more manageable!"
                                    </p>
                                </div>
                                <div className="testimonial-name">KHAN S. <span className="profession">Profession: Design Engineer</span></div>
                            </div>

                            {/* Testimonial 4 */}
                            <div className="testimonial-item">
                                <div className="shadow-effect">
                                    <img className="img-circle" src="assets/images/tbg_1.png" alt="" />
                                    <p>
                                        "Using AXN.AI has been a game-changer for our team. Alice's meeting assistance is seamless and incredibly productive. AXN.AI is a step ahead in AI-driven solutions."
                                    </p>
                                </div>
                                <div className="testimonial-name">MAX A. <span className="profession">Profession: Senior Procurement Manager</span></div>
                            </div>

                            {/* Testimonial 5 */}
                            <div className="testimonial-item">
                                <div className="shadow-effect">
                                    <img className="img-circle" src="assets/images/tbg_2.png" alt="" />
                                    <p>
                                        "AXN.AI's Hannah has become an indispensable part of my daily routines. A must-have for modern professionals!"
                                    </p>
                                </div>
                                <div className="testimonial-name">ALEXANDRA S. <span className="profession">Profession: Content Writer</span></div>
                            </div>

                            {/* Testimonial 6 */}
                            <div className="testimonial-item">
                                <div className="shadow-effect">
                                    <img className="img-circle" src="assets/images/tbg_1.png" alt="" />
                                    <p>
                                        "AXN.AI has exceeded my expectations. The AI-powered tools are robust and reliable. Alice's meeting management is seamless, and Hannah's responsive support is truly exceptional. The media upload feature is versatile and efficient. AXN.AI caters to every user, making it a standout choice in AI technology."
                                    </p>
                                </div>
                                <div className="testimonial-name">DAVID M. <span className="profession">Profession: Director of Sales</span></div>
                            </div>

                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonials;
