// UserProfileModal.jsx
import React, { useState } from 'react';
import './UserProfileModal.css';

function UserProfileModal({ isOpen, onClose, userData, onPhoneNumberUpdate }) {
  const [phoneNumber, setPhoneNumber] = useState(userData.phoneNumber );
  const [isEditing, setIsEditing] = useState(false);

  if (!isOpen) return null;

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleSavePhoneNumber = () => {
    onPhoneNumberUpdate(phoneNumber);
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCloseModal = () => {
    setIsEditing(false);
    onClose();
  };

  return (
    <div className="userProfileModal-backdrop">
      <div className="userProfileModal-content">
        <h2>User Profile</h2>
        <p>Name: {userData.name}</p>
        <p>Email: {userData.email}</p>
        <p>Date of Birth: {userData.dateOfBirth}</p>
        <p>Account Type: {userData.accountType}</p>
        <p>Credits: {userData.credits ? Number(userData.credits).toFixed(2) : '0.00'}</p>
        <div className="phone-number-field">
          <label>Phone Number:</label>
          {isEditing ? (
            <input type="tel" value={phoneNumber} onChange={handlePhoneNumberChange} />
          ) : (
            <span>{phoneNumber ? phoneNumber : userData.phoneNumber}</span>
          )}
        </div>
        <div className="setting-modal-buttons">
          {isEditing ? (
            <button onClick={handleSavePhoneNumber}>Save</button>
          ) : (
            <button onClick={handleEditClick}>Edit</button>
          )}
          <button onClick={handleCloseModal}>Close</button>
        </div>
      </div>
    </div>
  );
}

export default UserProfileModal;