// src/containers/context/AuthContext.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app'; // Import initializeApp
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import firebaseConfig from '../../firebaseConfig'; // Adjust the path as necessary based on your project structure

// Initialize Firebase App
const firebaseApp = initializeApp(firebaseConfig);

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const auth = getAuth(firebaseApp); // Pass the Firebase app instance to getAuth

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, isAuthenticated: !!user }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use auth context
export const useAuth = () => useContext(AuthContext);
