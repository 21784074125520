import React from 'react';
import ContactForm from '../../components/contactform/ContactForm';
import AgentCard from '../../components/cards/AgentCard';

const About = () => {

    return (
        <div className='container'>
            {/* About Us Banner Starts */}
            <div className="aboutus-banner">
                <div className="row">
                    <div className="col-md-6">
                        <div className="left">
                            <h2>About Us</h2>
                            <p>Lorem ipsum dolor sit amet consectetur. Aliquet nunc luctus pharetra sit maecenas. Vivamus scelerisque elementum cursus at sagittis nulla pellentesque.Lorem ipsum dolor sit amet consectetur. Aliquet nunc luctus pharetra sit maecenas. Vivamus scelerisque elementum cursus at sagittis nulla pellentesque.</p>
                        </div>
                    </div>
                    <div className="col-md-6"></div>
                </div>
            </div>
            {/* About Us Banner Ends */}

            {/* Single Man Section Starts */}
            <section className='single-man'>
                <div className="row">
                    <div className="col-md-6">
                        <img src="assets/images/others/singleman.png" className='card-img' alt="" />
                    </div>
                    <div className="col-md-6 d-flex align-items-center">
                        <div className="ps-3">
                            <p>Lorem ipsum dolor sit amet consectetur. Aliquet nunc luctus pharetra sit maecenas. Vivamus scelerisque elementum cursus at sagittis nulla pellentesque.</p>
                            <div className='v-img-section1 d-flex justify-content-center align-items-center mt-4'>
                                <a href=""><img src="assets/icons/play.png" className="card-img" alt="" /></a>
                            </div>
                            <p className='mt-4'>Lorem ipsum dolor sit amet consectetur. Aliquet nunc luctus pharetra sit maecenas. Vivamus scelerisque elementum cursus at sagittis nulla pellentesque.Lorem ipsum dolor sit amet consectetur. Aliquet nunc luctus pharetra sit maecenas. Vivamus scelerisque elementum cursus at sagittis nulla pellentesque.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* Single Man Section Ends */}

            {/* Agents Section Starts */}
            <section className='agents-section mt-6'>
                <div className="header mb-5">
                    <h2>Explore Our Elite Agent</h2>
                </div>
                <div className="row">
                    <AgentCard
                        heading="Grace Elizabeth"
                        img="assets/images/agents/agent1.png"
                        info="CEO and Co-Founder"
                    />
                    <AgentCard
                        heading="Karlo Merlin"
                        img="assets/images/agents/agent1.png"
                        info="CEO and Co-Founder"
                    />
                    <AgentCard
                        heading="Lucy Mae"
                        img="assets/images/agents/agent1.png"
                        info="CEO and Co-Founder"
                    />
                </div>
            </section>
            {/* Agents Section Ends */}

            {/* Contact Us Section Starts */}
            <ContactForm />
            {/* Contact Us Section Ends */}

        </div>
    );
}

export default About;