import React, { useRef, useState, useEffect } from "react";
import ProgressBar3D from './ProgressBar3D';

const DashboardSidebar = ({
  isDarkMode,
  transcriptions,
  onSelectTranscription,
  onDeleteTranscription,
  userName,
  handleFileUpload,
  userCredits,
  handleTranscribeVideo,
  toggleBillingModal,
  setTranscriptions,
  isUploading,
  progress,
  uploadProgress,
  statusText,
  buyCredits, // The buyCredits function is passed as a prop
  firstName,
  uuid,
  onTitleUpdate
}) => {
  const fileInputRef = useRef(null);
  const [isOptionsMenuVisible, setIsOptionsMenuVisible] = useState(false);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [selectedTranscriptionId, setSelectedTranscriptionId] = useState(null);
  const [isMeetingAssistantModalOpen, setIsMeetingAssistantModalOpen] = useState(false);
  const [meetingDetails, setMeetingDetails] = useState({
    meeting_system_number: '',
    password: '',
    meeting_duration_minutes: '30',
    greetingOption: '',
    meeting_platform: 'google_meet',
    attendee_id: '', // New field for attendee ID
  });
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const stripeButtonRef = useRef(null);

  const showWhatsApp = () => {
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const handleMeetingAssistant = () => {
    setIsMeetingAssistantModalOpen(!isMeetingAssistantModalOpen);
  };

  const triggerFileInputClick = () => {
    fileInputRef.current.click();
  };

  const toggleOptionsMenu = () => {
    setIsOptionsMenuVisible(!isOptionsMenuVisible);
  };

  const toggleSidebar = () => {
    const container = document.querySelector(".dashboard-container");
    const sidebar = document.querySelector(".dashboard-sidebar");

    if (container && sidebar) {
      const isCollapsed = container.classList.contains("sidebar-collapsed");

      container.classList.toggle("sidebar-collapsed");

      if (isCollapsed) {
        sidebar.style.width = "300px";
      } else {
        sidebar.style.width = "5px";
      }
    } else {
      console.error("Error: dashboard elements not found");
    }
  };

  const handleToggleBillingModal = () => {
    setIsBillingModalOpen(!isBillingModalOpen);
    if (!isBillingModalOpen) {
      toggleBillingModal();
    }
  };

  const handleSelectTranscription = (id) => {
    onSelectTranscription(id);
    setSelectedTranscriptionId(id);
  };

  const handleDeleteClick = (transcriptionId, e) => {
    e.stopPropagation();
    const isConfirmed = window.confirm("Are you sure you want to delete this transcription?");
    if (isConfirmed) {
      onDeleteTranscription(transcriptionId);
    }
  };

  const startMeetingAssistant = async () => {
    const { meeting_system_number, password, meeting_duration_minutes, greetingOption, meeting_platform, attendee_id } = meetingDetails;
    const user = firstName;
    const userUuid = uuid;
    let greet = Number(greetingOption);

    if (!meeting_duration_minutes || meeting_duration_minutes === '0') {
      alert("Please select a meeting duration.");
      return;
    }

    const isValidMeetingSystemNumber = /^\+\d{11,15}$/.test(meeting_system_number);
    if (!isValidMeetingSystemNumber) {
      alert("Meeting system number must be in the format +[CountryCode][Number] and be between 11 to 15 digits long.");
      return;
    }

    const isValidPassword = /^\d{6,15}$/.test(password);
    if (!isValidPassword) {
      alert("Password must be 6 to 15 digits long.");
      return;
    }

    if (meeting_platform === 'webex' && !attendee_id) {
      alert("Please enter your attendee ID for Webex.");
      return;
    }

    const requestBody = {
      meeting_system_number,
      password,
      meeting_duration_minutes: parseInt(meeting_duration_minutes, 10),
      user,
      user_uuid: userUuid,
      greetingOption: parseInt(greet, 10),
      meeting_platform,
      attendee_id, // Include attendee ID for Webex
    };

    try {
      const response = await fetch('https://us-central1-whatsappinitiative.cloudfunctions.net/meetast', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      alert('Alice meeting assistant will join the call shortly.');
      setIsMeetingAssistantModalOpen(false);
    } catch (error) {
      console.error('Error starting the meeting assistant:', error);
      alert('Failed to start the meeting assistant. Please check the console for more details.');
    }
  };

  return (
    <div className="dashboard-container">
      <aside
        className={`dashboard-sidebar  px-3 py-4 ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <button className="toggle-sidebar-btn pulse blue-nav-btn-wrapper" onClick={toggleSidebar}>
          <img src="../../../../../assets/icons/ai.png" alt="display" />
        </button>
        {isMeetingAssistantModalOpen && (
          <div className="meeting-assistant-modal">
            <div className="meeting-assistant-content">
              <h3>Invite Alice Meeting Assistant</h3>
              <select
                value={meetingDetails.meeting_platform}
                onChange={(e) =>
                  setMeetingDetails({
                    ...meetingDetails,
                    meeting_platform: e.target.value
                  })
                }
              >
                <option value="google_meet">Google Meet</option>
                <option value="webex">Webex</option>
              </select>
              <input
                type="text"
                placeholder="Phone Number in format +11234567890"
                value={meetingDetails.meeting_system_number}
                onChange={(e) =>
                  setMeetingDetails({
                    ...meetingDetails,
                    meeting_system_number: e.target.value
                  })
                }
              />
              <input
                type="text"
                placeholder="Meeting Pin"
                value={meetingDetails.password}
                onChange={(e) =>
                  setMeetingDetails({
                    ...meetingDetails,
                    password: e.target.value
                  })
                }
              />
              {meetingDetails.meeting_platform === 'webex' && (
                <input
                  type="text"
                  placeholder="Attendee ID"
                  value={meetingDetails.attendee_id}
                  onChange={(e) =>
                    setMeetingDetails({
                      ...meetingDetails,
                      attendee_id: e.target.value
                    })
                  }
                />
              )}
              <select
                value={meetingDetails.meeting_duration_minutes}
                onChange={(e) =>
                  setMeetingDetails({
                    ...meetingDetails,
                    meeting_duration_minutes: e.target.value
                  })
                }
              >
                <option value="15">15 minutes</option>
                <option value="30">30 minutes</option>
                <option value="45">45 minutes</option>
                <option value="60">60 minutes</option>
                <option value="75">75 minutes</option>
                <option value="90">90 minutes</option>
                <option value="105">105 minutes</option>
                <option value="120">120 minutes</option>
              </select>
              <select
                value={meetingDetails.greetingOption}
                onChange={(e) =>
                  setMeetingDetails({
                    ...meetingDetails,
                    greetingOption: e.target.value
                  })
                }
              >
                <option value="0">No announcement message</option>
                <option value="1">Joining on behalf of {firstName}</option>
                <option value="2">I have been invited by {firstName}</option>
              </select>
              <button onClick={startMeetingAssistant}>Start Meeting</button>
              <button onClick={() => setIsMeetingAssistantModalOpen(false)}>Cancel</button>
            </div>
          </div>
        )}
        <div className="sidebar-links mt-5">
          <div className="sidebar-links-inner">
            <ul className="nav nav-tabs border-0 flex-column">
              {transcriptions.map((transcription) => (
                <li key={transcription.id} className="nav-item" role="presentation">
                  <button
                    className={`nav-link border ${selectedTranscriptionId === transcription.id ? 'selected' : ''}`}
                    onClick={() => handleSelectTranscription(transcription.id)}
                  >
                    <div className="transcription-info">
                      <div className="transcription-title-container">
                        {isEditing && selectedTranscriptionId === transcription.id ? (
                          <input
                            type="text"
                            className="transcription-title-input"
                            value={newTitle}
                            onChange={(e) => setNewTitle(e.target.value)}
                            onBlur={() => {
                              const currentTranscription = transcriptions.find(t => t.id === transcription.id);
                              if (currentTranscription) {
                                const currentFileName = currentTranscription.fileName;
                                const extIndex = currentFileName.lastIndexOf('.');
                                const ext = extIndex !== -1 ? currentFileName.substring(extIndex) : '';
                                const newFileName = `${newTitle}${ext}`;
                                onTitleUpdate(transcription.id, newTitle, newFileName);
                                setIsEditing(false);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                const currentTranscription = transcriptions.find(t => t.id === transcription.id);
                                if (currentTranscription) {
                                  if (currentTranscription.fileName) {
                                    const currentFileName = currentTranscription.fileName;
                                    const extIndex = currentFileName.lastIndexOf('.');
                                    const ext = extIndex !== -1 ? currentFileName.substring(extIndex) : '';
                                    const newFileName = `${newTitle}${ext}`;
                                    onTitleUpdate(transcription.id, newTitle, newFileName);
                                  } else {
                                    onTitleUpdate(transcription.id, newTitle, '');
                                  }
                                  setIsEditing(false);
                                }
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          <span className="transcription-title">{transcription.audioName}</span>
                        )}
                      </div>
                      <div className="button-container">
                        <button className="edit-button" onClick={() => {
                          setIsEditing(true);
                          setNewTitle(transcription.audioName);
                        }}>Edit</button>
                        <button className="trash-button" onClick={(e) => {
                          e.stopPropagation();
                          if (window.confirm("Are you sure you want to delete this transcription?")) {
                            onDeleteTranscription(transcription.id);
                          }
                        }}>
                          <img src="../../../../../assets/icons/trash.png" alt="Trash"/>
                        </button>
                      </div>
                    </div>
                    <small className="transcription-date">
                      {transcription.date} at {transcription.time}
                    </small>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileUpload}
        />
        {isUploading && (
          <div className="upload-progress-container">
            <ProgressBar3D progress={uploadProgress}/>
            <div className="status-text logo-animate">{statusText} {uploadProgress}%</div>
          </div>
        )}
        <div className="bottom-stuff mt-auto px-3">
          <div className="icons-row d-flex justify-content-between align-items-center">
            <button
              onClick={triggerFileInputClick}
              className="icon-button"
              title="Upload File"
            >
              <img
                src="../../../../../assets/icons/upload_1.png"
                alt="Upload File"
              />
            </button>

            <button
              onClick={handleMeetingAssistant}
              className="icon-button"
              title="Alice Meeting Assistant"
            >
              <img
                src="../../../../../assets/icons/alice_1.png"
                alt="Alice Meeting Assistant"
              />
            </button>

            <button
              onClick={handleTranscribeVideo}
              className="icon-button"
              title="Transcribe YouTube Video"
            >
              <img
                src="../../../../../assets/icons/video_2.png"
                alt="Transcribe Video"
              />
            </button>
            <button
              onClick={showWhatsApp}
              className="icon-button"
              title="WhatsApp"
            >
              <img
                src="../../../../../assets/icons/whatsapp_1.png"
                alt="WhatsApp"
              />
            </button>
          </div>

          <br /> <br />
          <div className="user-info">
            <div className="background-image"></div>
            <p>Welcome Back</p>
            <h4>{firstName}</h4>
            <div className="credits-container">
              <span>
                {/* Use the buyCredits function directly on the button click                 <button className="icon-button buy-credits-button" onClick={buyCredits}>$</button> */}
                <button onClick={buyCredits} className="icon-button buy-credits-button">$</button>
                Credits: {(userCredits || 0).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      </aside>
      {showModal && (
        <div className="whatsapp-modal-background" onClick={hideModal}>
          <div
            className="whatsapp-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <p>Contact Hannah on WhatsApp: +15484890093</p>
            <button onClick={hideModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardSidebar;
