import React, { useState, useEffect, useRef } from 'react';
import useInView from '../../../components/inView/useInView';


const lines = [
    "Where Every File Becomes A Conversation, And Every Piece Of Data Tells A Story.",
    "Revolutionize Your Workday – Discover AI, Reimagined.",
    "Summarize, Translate, Transform – All with AXN. ai.",
    "The Benchmark for Brilliance – Trusted by Industry Leaders.",
    "Language is No Barrier – Chat with Hannah in 100+ Languages.",
    "Meetings Made Easy – Alice Attends, So You Don't Have To."
  ];

const HomeBanner = ({isDarkMode}) => {
  const [setRef, inView] = useInView({
    threshold: 0.1, // Trigger when the section is at least 10% in view
  });
  const [currentLine, setCurrentLine] = useState(0);
  const [loadSVG, setLoadSVG] = useState(false); // State to control SVG loading
  const svgContainerRef = useRef(null); // Ref for the SVG container

  // Placeholder SVG or loading spinner
  const Placeholder = () => <div>Loading...</div>;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLine((currentLine) => (currentLine + 1) % lines.length);
    }, 5000); // Adjust as needed

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // Trigger load when the SVG container is visible
        if (entries[0].isIntersecting) {
          setLoadSVG(true);
          observer.disconnect(); // Disconnect observer after loading SVG
        }
      },
      { threshold: 0.5 } // Trigger when 10% of the element is in view
    );

    if (svgContainerRef.current) {
      observer.observe(svgContainerRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, []);

  return (
    <section className={`Home-Banner ${inView ? 'in-view' : ''}`} ref={setRef}>
      <div className="home-banner">
        <div className="row">
          <div className="col-md-6 d-flex justi-content-center align-items-center">
            <div className="left">
              {lines.map((line, index) => (
                <h1
                  key={index}
                  className={`banner-text ${currentLine === index ? "visible" : ""}`}
                >
                  {line}
                </h1>
              ))}
            </div>
          </div>
          <div className="col-md-6">
          <div className="right globe-gif-container">
            <img 
              src={isDarkMode ? "assets/video/globe_dark.gif" : "assets/video/globe_light.gif"}
              alt="Globe GIF"
              className="globe-gif img-fluid"
            />
          </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
