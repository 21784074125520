import React, { useState } from 'react';
import PricingModal from './PricingModal'; 
import './BillingModal.css'; // Make sure this path is correct

const BillingModal = ({ isOpen, onClose, billingData, buyCredits }) => {
    const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);

    const handleTogglePricingModal = () => {
        setIsPricingModalOpen(!isPricingModalOpen);
    };

    if (!isOpen) return null;

    return (
        <div className="billingModal-backdrop">
            <div className="billingModal-dialog">
                <div className="billingModal-content">
                    <div className="billingModal-header">
                        <h5 className="billingModal-title">Billing Information</h5>
                        <div className="billingButtonTray">
                            <button type="button" className="billingModal-button" onClick={handleTogglePricingModal}>{isPricingModalOpen ? 'Hide' : 'Pricing'}</button>
                            <button type="button" className="billingModal-close" onClick={onClose}> <img src="../../../../../assets/icons/close.png" alt="Billing" /></button>
                        </div>
                    </div>
                    <div className="billingModal-body">
                        {!isPricingModalOpen ? (
                            billingData && billingData.length > 0 ? (
                                <table className="billingModal-table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Credits</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {billingData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.created}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.price}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p>No billing details available.</p>
                            )
                        ) : (
                            <PricingModal isOpen={isPricingModalOpen} onClose={() => setIsPricingModalOpen(false)} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BillingModal;
