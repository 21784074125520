import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import { AuthProvider, AuthContext } from './containers/context/AuthContext';
import { Transcription, Home, About, Services, Dashboard } from './containers';
import Footer from './containers/footer/Footer';
import './App.css';
import ExploringSection from './containers/home/sections/ExploringSection';
import ContactForm from './components/contactform/ContactForm';
import HomeContent from './containers/home/HomeContent';

const AppFooter = ({ toggleTheme, isDarkMode }) => {
  const location = useLocation();
  const isDashboard = location.pathname.startsWith('/dashboard');
  return !isDashboard ? <Footer toggleTheme={toggleTheme} isDarkMode={isDarkMode} /> : null;
};

const App = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [theme, setTheme] = useState('light');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);

  const toggleSignupPopup = () => {
    setShowSignUpPopup(!showSignUpPopup);
  };

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    setIsDarkMode(!isDarkMode);
    if (newTheme === 'dark') {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  };

  useEffect(() => {
    document.body.classList.remove('dark-mode');
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Navbar 
          toggleTheme={toggleTheme} 
          isDarkMode={isDarkMode} 
          toggleSignupPopup={toggleSignupPopup}
          showSignUpPopup={showSignUpPopup}
        />
        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/about" element={<About toggleTheme={toggleTheme} isDarkMode={isDarkMode} />} />
          <Route path="/services" element={<Services toggleTheme={toggleTheme} isDarkMode={isDarkMode} />} />
          <Route path="/home" element={
            <Home 
              toggleTheme={toggleTheme} 
              isDarkMode={isDarkMode} 
              toggleSignupPopup={toggleSignupPopup}
            />
          }>
            <Route index element={
              <HomeContent 
                toggleTheme={toggleTheme} 
                isDarkMode={isDarkMode} 
                toggleSignupPopup={toggleSignupPopup} 
              />
            } />
            <Route path="products" element={<ExploringSection />} />
            <Route path="contact" element={<ContactForm />} />
          </Route>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
              </ProtectedRoute>
            }
          />
        </Routes>
        <AppFooter toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
      </Router>
    </AuthProvider>
  );
};

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  if (!user) {
    return <Navigate to="/home" replace />;
  }
  return children;
};

export default App;