import React, { useEffect, useContext } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Link } from 'react-scroll';
import { AuthContext } from '../../containers/context/AuthContext';
import HomeBanner from './sections/HomeBanner';
import VImgSection from './sections/VImgSection';
import ExploringSection from './sections/ExploringSection';
import TwoStepsSection from './sections/TwoStepsSection';
import ContactForm from '../../components/contactform/ContactForm';
import PromotionDisplay from './sections/PromotionDisplay'; 
import Promo2 from './sections/Promo2'; 

const Home = ({ toggleTheme, isDarkMode, toggleSignupPopup }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  const renderHomeContent = () => (
    <>
      {/* Home Banner Starts */}
      <HomeBanner isDarkMode={isDarkMode} />
      {/* Home Banner Ends */}
      
      {/* V-img-section Starts */}
      <VImgSection />
      {/* V-img-section Ends */}

      <PromotionDisplay toggleSignupPopup={toggleSignupPopup} />

      {/* Exploring Section Starts */}
      <section id="products">
        <ExploringSection />
      </section>
      {/* Exploring Section Ends */}

      {/* Two Steps Section Starts */}
      <TwoStepsSection />
      {/* Two Steps Section Ends */}

      {/* Contact Us Section Starts */}
      <section id="contact">
        <ContactForm />
      </section>
      {/* Contact Us Section Ends */}
    </>
  );

  return (
    <div className={`container ${isDarkMode ? 'dark-mode' : ''}`}>
      {location.pathname === '/home' ? renderHomeContent() : <Outlet />}
    </div>
  );
};

export default Home;