import React, { useState, useRef, useEffect } from "react";


const BigCard = ({ desc, onSubmitQuestion, chatHistory, commonQuestions, isQuestionSent, setIsQuestionSent, selectedTranscriptionId }) => {
    const [question, setQuestion] = useState("");
    const chatHistoryEndRef = useRef(null);

    const handleQuestionSubmit = (event) => {
        event.preventDefault();
        if (question.trim() !== "") {
            setIsQuestionSent(true);
            onSubmitQuestion(question, selectedTranscriptionId);
            setQuestion(""); // Clear the input after submitting
        }
    };

    useEffect(() => {
        //chatHistoryEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [chatHistory]);

    // Utilize this function to ensure text formatting and respect line breaks
    const formatTextForDisplay = (text, type) => {
        // This could be expanded to include more sophisticated formatting
        return text.split('\n').map((line, index) => <span key={index}>{line}<br/></span>);
    };
      // Check if chatHistory is available
  if (!chatHistory) {
    return <div>Loading chat history...</div>;
  }

    return (
        <div className="col-xl-8 cardHistoryCont" style={{ display: 'flex', flexDirection: 'column', maxHeight: '70vh', overflowY: 'auto', width: '100%'}}>
            <div className="cardHistory d-flex flex-column justify-content-between big-side-card border-0 px-3 py-4" style={{ height: 'auto', overflow: 'hidden', flexDirection:'column' }}>
                {/* Your existing code for content... */}
                <div className="content" style={{ width: '100%' }}>
                    <div className="chat-history" style={{maxHeight: '50vh', maxWidth: '100%', minWidth:'230px'}}>
                        {chatHistory.map((chat, index) => (
                            <div key={index} className={`chat-message ${chat.type === 'user' ? 'question' : 'answer'}`}>
                                {/* Apply the formatting function directly */}
                                {formatTextForDisplay(chat.text, chat.type)}
                            </div>
                        ))}
                        {isQuestionSent && <div className="ellipsis"><p>Sending...</p></div>}<br />
                        <div className="common-questions"style={{ width: '100%' }}>
                        <h5>Common Questions:</h5>
                            <ul>
                                {commonQuestions.map((question, index) => (
                                    <li key={index}>
                                        <button onClick={() => {
                                                setIsQuestionSent(true);
                                                onSubmitQuestion(question, selectedTranscriptionId);
                                            }} className="common-question-button">
                                                {question}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div ref={chatHistoryEndRef} />
                    </div>
                </div>
                <form className="input-f" onSubmit={handleQuestionSubmit} style={{ paddingTop: '0' }}>
                <textarea
                    className="form-control big-input py-3"
                    placeholder="Type your question here and press enter..."
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    onKeyDown={(e) => {
                        // Check if 'Enter' is pressed without the 'Shift' key
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault(); // Prevent the default action to avoid line break in textarea
                            handleQuestionSubmit(e); // Call the submission handler
                        }
                    }}
                    rows="3"
                ></textarea>
                </form>
            </div>
        </div>
    );
};

export default BigCard;
