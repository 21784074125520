import React from 'react';
import './BillingModal.css'; // Make sure this path is correct

const PricingModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="billingModal-backdrop"> {/* Use billingModal classes for unified styling */}
      <div className="billingModal-content">
        <div className="billingModal-header">
          <h5 className="billingModal-title">Pricing Details (credits)</h5>
          <button type="button" className="billingModal-close" onClick={onClose}>&times;</button>
        </div>
        <div className="billingModal-body">
          <section>
            <p><strong>Upload PDF or Word, get summary:</strong> 37.5 credits / 2 pages</p>
            <p><strong>Upload Audio, get transcription and summary:</strong> 37.5 credits / 20 min</p>
            <p><strong>Upload YouTube video link, get transcription and summary:</strong> 37.5 credits / 20 minutes</p>
            <p><strong>Invite Alice to attend meeting, get transcription and summary:</strong> 127.5 credits / 20 minutes</p>
            <p><strong>Invite Alice to attend meeting for less than 1 min (e.g., wrong number or password):</strong> 15 credits for any call less than 1 min</p>
            <p><strong>Chat with any document:</strong> 37.5 credits / question</p>
            <p><strong>Chat with Hannah:</strong> 22.5 credits / question</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PricingModal;
