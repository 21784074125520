// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDnBC5Dw9k-gbnNPGrAvj3flBjUBY7b6fs",
  authDomain: "whatsappinitiative.firebaseapp.com",
  projectId: "whatsappinitiative",
  storageBucket: "whatsappinitiative.appspot.com",
  messagingSenderId: "628789528132",
  appId: "1:628789528132:web:b0eda1c047b22daae09e11",
  measurementId: "G-XEZD454ZRE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default firebaseConfig;


