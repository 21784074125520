import React from "react";
import TwoStepsLfCard from "../../components/cards/TwoStepsLrCard";
import TwoStepsRlCard from "../../components/cards/TwoStepsRlCard";

const Services = () => {

    return (
        <div className="container mb-5">
            <div className="services-wrapper">
                <div className="services-banner mb-5 text-center px-5 mx-4">
                    <h1 className="txt-black1 font-40">The Future is Now and You Just Need to Realize It. Step into Future Today. Make it Happen.</h1>
                </div>
            </div>
            <div className="services-cards-list">
                <div className="row px-0 mx-0">
                    <TwoStepsLfCard
                        heading="Audio"
                        img="assets/images/others/blackimg.png"
                        desc="AXN makes listening to your audio transcriptions like having a chat, where AI helps you talk to the words and understand them better. It's like your recordings come alive, making learning and understanding way more fun and easy!"
                    />
                    <TwoStepsRlCard
                        heading="Video"
                        img="assets/images/others/blackimg.png"
                        desc="With AXN, watching video transcriptions is like chatting with the video itself, where AI makes every scene talk back to you, making it super easy and fun to learn. It's like your favorite videos turn into a cool, interactive conversation, helping you understand and enjoy every bit!"
                    />
                    <TwoStepsLfCard
                        heading="PDF & Word"
                        img="assets/images/others/blackimg.png"
                        desc="AXN turns your PDFs and Word documents into chatty pals, with AI helping you talk to the text and making learning from them super easy and fun. It's like your documents come to life, turning reading into a cool, interactive chat that helps you understand everything better!"
                    />
                    <TwoStepsRlCard
                        heading="Excel"
                        img="assets/images/others/blackimg.png"
                        desc="AXN makes your Excel sheets talk, using AI to turn columns and numbers into easy, fun conversations, helping you understand data like never before. It's like your spreadsheets become friendly guides, making number crunching and data analysis super cool and interactive!"
                    />
                </div>
            </div>
        </div>
    );
}

export default Services;