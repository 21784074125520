import React from 'react';
import './PromotionDisplay.css';

const PromotionDisplay = ({ toggleSignupPopup }) => {
  return (
    <div className="promotion-container">
      <p></p>
      <p className="promotion-text banner-text2 visible">Unlock 1500 Credits Today – Absolutely Risk-Free! Limited Time Only!</p>
      <div className="btn-container">
        <div className="btn2"></div>
        <div className="btn" onClick={toggleSignupPopup}>Sign Up Now</div>
      </div>
    </div>
  );
};

export default PromotionDisplay;