import React, { useState, useContext, useEffect } from 'react';
import './navbar.css';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { AuthContext } from '../../containers/context/AuthContext';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, doc, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDnBC5Dw9k-gbnNPGrAvj3flBjUBY7b6fs",
  authDomain: "whatsappinitiative.firebaseapp.com",
  projectId: "whatsappinitiative",
  storageBucket: "whatsappinitiative.appspot.com",
  messagingSenderId: "628789528132",
  appId: "1:628789528132:web:b0eda1c047b22daae09e11",
  measurementId: "G-XEZD454ZRE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const Navbar = ({ toggleTheme, isDarkMode, toggleSignupPopup, showSignUpPopup }) => {
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [accountType, setAccountType] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const isAuthenticated = Boolean(user);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const toggleLoginPopup = () => {
    setShowLoginPopup(!showLoginPopup);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showLoginPopup && !document.querySelector(".nav-login-card").contains(event.target)) {
        setShowLoginPopup(false);
      }
      if (showSignUpPopup && !document.querySelector(".nav-signup-card").contains(event.target)) {
        toggleSignupPopup();
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showLoginPopup, showSignUpPopup, toggleSignupPopup]);

  const logoSvgLight = (
    <svg width="85" height="51" viewBox="0 0 85 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* Your SVG path for light mode */}
            <path d="M64.3857 22.1083C64.3232 22.8114 64.2088 23.5144 64.2067 24.2175C64.1922 29.228 64.188 34.2384 64.2213 39.2469C64.2213 40.0059 64.0299 40.2838 63.2392 40.2631C61.7329 40.2112 60.2244 40.2319 58.716 40.2631C58.0918 40.2631 57.8192 40.0702 57.8192 39.3983C57.8275 30.1571 57.8275 20.9159 57.8192 11.6747C57.8192 10.5817 57.8504 10.5693 58.9698 10.5651C60.0309 10.5651 61.0941 10.6149 62.1511 10.5444C63.0041 10.4884 63.5825 10.8306 64.0944 11.4486C65.7797 13.4872 67.4796 15.5113 69.1773 17.5396C71.749 20.6151 74.3144 23.6949 76.911 26.7476C77.1748 27.0162 77.5328 27.1723 77.9097 27.1831C79.9134 27.1935 81.917 27.1624 83.9206 27.1002C84.7134 27.0753 85.0254 27.3635 84.9984 28.162C84.9568 29.4685 84.9797 30.7792 84.9984 32.0878C84.9984 32.6913 84.7903 32.9672 84.1204 32.963C80.9994 32.9423 77.8639 32.9298 74.7388 32.9879C73.7173 33.0086 73.1909 32.3201 72.6936 31.7228C70.2738 28.8194 67.9373 25.8351 65.557 22.8923C65.2617 22.5679 64.9455 22.2631 64.6104 21.9798L64.3857 22.1083Z" fill="#161616"/>
            <path d="M19.0156 27.1064C21.4041 27.1064 23.5701 27.0815 25.7339 27.1333C25.9235 27.1549 26.1059 27.2177 26.2684 27.3174C26.4309 27.417 26.5695 27.551 26.6743 27.7099C27.6044 29.3876 28.4574 31.1048 29.3479 32.8054C29.5706 33.2326 29.8327 33.6349 30.149 34.1783C31.4098 32.6872 32.602 31.2749 33.7984 29.8605C35.2132 28.1903 36.6308 26.5257 38.0512 24.8666C39.5971 23.0561 41.1388 21.2414 42.6972 19.4413C43.9664 17.973 45.271 16.5379 46.5381 15.0634C47.5284 13.9103 48.5147 12.7531 49.4509 11.5502C50.5349 10.1462 52.0933 10.0985 53.144 11.5191C53.6059 12.1413 54.0221 12.8028 54.434 13.4603C54.9583 14.2898 54.8502 15.0882 54.226 15.8307C50.9344 19.6784 47.645 23.5262 44.3576 27.3739C42.2124 29.8916 40.084 32.4217 37.943 34.9394C36.5448 36.5819 35.1425 38.2203 33.7173 39.842C33.5237 40.0631 33.2559 40.2061 32.9641 40.2444C30.8543 40.3398 28.7425 40.4352 26.6327 40.4186C26.3843 40.3911 26.1452 40.3087 25.9329 40.1774C25.7205 40.0461 25.5402 39.8691 25.4052 39.6595C23.6512 36.3123 21.9721 32.9277 20.2702 29.5535L19.0156 27.1064Z" fill="#161616"/>
            <path d="M84.8465 22.745C82.9739 22.745 81.2179 22.7658 79.4681 22.716C79.2891 22.716 78.9833 22.3012 78.977 22.071C78.9292 20.6815 78.9604 19.2879 78.9479 17.8984C78.9125 13.7921 77.2834 10.4324 73.9315 8.06405C72.2233 6.85084 70.228 6.07313 68.0642 6.05239C60.6759 5.98188 53.2917 5.94248 45.8972 5.8927C44.7008 5.8927 44.6072 5.79731 44.6051 4.6152C44.603 3.43309 44.6051 2.28001 44.6051 1.11034C44.5947 0.380339 44.9443 0.0443714 45.66 0.0443714C53.0296 0.0443714 60.4012 -0.0821359 67.7645 0.0920697C72.0798 0.19369 75.906 1.84657 79.0665 4.90139C81.6069 7.35064 83.4795 10.1607 84.4033 13.5951C84.9651 15.6959 84.784 17.8258 84.8444 19.9494C84.8673 20.8371 84.8465 21.7247 84.8465 22.745Z" fill="#161616"/>
            <path d="M5.71175 22.5854C3.92658 22.5854 2.25376 22.602 0.583015 22.5605C0.45642 22.5259 0.34073 22.4598 0.246826 22.3684C0.152921 22.277 0.0838867 22.1632 0.0462136 22.0379C0.112794 18.5724 -0.369911 15.0697 1.00538 11.7058C2.07695 9.09496 3.67858 6.73314 5.70967 4.76872C8.273 2.25311 11.371 0.888499 14.8311 0.233154C15.6815 0.093034 16.5432 0.0326287 17.4049 0.0527278C24.4124 0.0389019 31.4206 0.0389019 38.4296 0.0527278C39.6613 0.0527278 40.0545 0.436395 40.0379 1.69524C40.0254 2.80476 39.9214 3.91636 39.9505 5.02381C39.9713 5.86788 39.576 6.06075 38.8353 6.06075C31.4345 6.04831 24.0358 6.07941 16.6371 6.04001C14.1175 6.02549 12.0077 7.02095 10.1081 8.49133C7.68626 10.3578 6.26104 12.9108 5.77001 15.9117C5.58275 17.0523 5.718 18.2448 5.71175 19.4124C5.70967 20.4286 5.71175 21.4531 5.71175 22.5854Z" fill="#161616"/>
            <path d="M56.5728 50.9788H46.4443C44.9983 50.9788 44.7798 50.7486 44.7798 49.2948C44.7798 48.3754 44.7798 47.4574 44.7798 46.5407C44.7798 45.3462 44.8235 45.2964 45.9949 45.2964C52.3866 45.2964 58.7783 45.3379 65.1679 45.2549C67.0758 45.23 69.0108 45.3026 70.8958 44.668C73.8795 43.6622 76.0579 41.7459 77.3978 38.9856C78.0511 37.6375 78.8376 37.1191 80.2857 37.2829C81.326 37.397 82.4038 37.2974 83.4649 37.2974C84.2971 37.2974 84.3866 37.4405 84.1577 38.2079C83.3442 40.9433 81.7983 43.1852 79.8321 45.2466C77.9017 47.309 75.4987 48.8745 72.8287 49.8091C71.3973 50.3069 69.8618 50.7175 68.3575 50.7693C64.4376 50.9 60.5094 50.8129 56.5853 50.8129L56.5728 50.9788Z" fill="#161616"/>
            <path d="M0.691467 37.4654C2.53074 37.4654 4.31175 37.4467 6.10109 37.4882C6.3258 37.4882 6.59628 37.7868 6.75857 38.0108C7.7323 39.363 8.57703 40.8354 9.68184 42.0797C11.3047 43.8985 13.5331 44.7156 15.8904 45.1221C16.8236 45.2595 17.7666 45.3192 18.7097 45.3005C25.2969 45.3088 31.8856 45.3088 38.4756 45.3005C39.724 45.3005 40.0194 45.7588 39.828 47.0197C39.7093 47.9829 39.6849 48.9553 39.7552 49.9232C39.7926 50.7527 39.3786 50.9891 38.6566 50.9871C37.1773 50.9871 35.698 50.9871 34.2187 50.9871C28.7466 50.9871 23.2766 51.0161 17.8067 50.9871C13.6121 50.958 9.93152 49.4814 6.68575 46.8683C3.95181 44.6679 2.18119 41.8143 1.00356 38.5583C0.939062 38.3799 0.907852 38.1912 0.855837 38.0087C0.803821 37.8262 0.760128 37.6873 0.691467 37.4654Z" fill="#161616"/>
            <path d="M16.088 20.1879C15.5949 20.949 15.1268 21.5505 14.796 22.2162C14.5005 22.8114 14.101 22.9441 13.481 22.9296C11.8352 22.8881 10.1874 22.9296 8.54162 22.9006C8.2004 22.9006 7.86126 22.7222 7.39728 22.5895C8.26698 21.1149 9.01808 19.7918 9.81912 18.4977C11.2756 16.1584 12.759 13.8481 14.1884 11.5046C14.3546 11.1873 14.6111 10.9261 14.9258 10.7538C15.2404 10.5814 15.5992 10.5057 15.957 10.5361C18.4121 10.5713 18.4079 10.5361 19.5377 12.7282C21.1003 15.754 22.6774 18.7694 24.2399 21.7952C24.6956 22.6807 24.5437 22.913 23.5408 22.9151C21.7827 22.9151 20.0246 22.9047 18.2665 22.9151C17.7983 22.9151 17.5424 22.8238 17.3406 22.3261C17.0763 21.65 16.6082 21.0444 16.088 20.1879Z" fill="#161616"/>
            <path d="M8.06937 27.2661C10.3851 27.2661 12.7008 27.291 15.0145 27.2516C15.6845 27.2412 16.1276 27.459 16.4272 28.0293C17.1555 29.4229 17.8837 30.8186 18.614 32.2081C18.9115 32.7702 18.6972 32.9713 18.125 32.9361C17.3261 32.8884 16.5271 32.7888 15.7261 32.7868C10.8179 32.7702 5.9076 32.7619 0.999411 32.7868C0.231661 32.7868 -0.0409012 32.5897 0.00487255 31.8037C0.0652096 30.6099 0.0652096 29.4139 0.00487255 28.2201C-0.0304981 27.4797 0.250386 27.2516 0.955718 27.2578C3.32555 27.2827 5.69746 27.2682 8.06729 27.2682L8.06937 27.2661Z" fill="#161616"/>
            <path d="M45.0087 32.4011L48.8017 27.71C49.713 28.7822 50.5411 29.732 51.3442 30.7046C52.3325 31.8992 53.2688 33.1373 54.2841 34.307C54.9583 35.0826 54.7793 35.8437 54.3195 36.5509C53.5773 37.6997 52.773 38.8074 51.9102 39.8691C51.5523 40.3067 51.0197 40.2175 50.6347 39.7198C49.4093 38.1353 48.1713 36.5613 46.9479 34.9768C46.3133 34.1535 45.6974 33.3177 45.0087 32.4011Z" fill="#161616"/>
            <path d="M35.4566 21.93C33.8025 19.8292 32.1443 17.7387 30.5047 15.6316C29.8472 14.7876 29.8493 14.1281 30.4589 13.2156C30.8272 12.666 31.2017 12.1206 31.5908 11.5855C32.6811 10.0944 33.9169 10.1151 35.0342 11.5565C36.3013 13.1907 37.6184 14.7855 38.95 16.3679C39.3952 16.8967 39.595 17.2741 39.0498 17.915C38.3799 18.7072 37.9513 19.7027 37.2834 20.497C36.8194 21.0507 36.1307 21.4157 35.4566 21.93Z" fill="#161616"/>
      </svg>
    );

const logoSvgDark = (
     <svg width="85" height="51" viewBox="0 0 85 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* Your SVG path for dark mode */}
        <path d="M64.3855 22.108C64.3231 22.811 64.2087 23.5141 64.2066 24.2171C64.192 29.2276 64.1879 34.2381 64.2212 39.2465C64.2212 40.0055 64.0298 40.2834 63.2391 40.2627C61.7327 40.2108 60.2243 40.2316 58.7158 40.2627C58.0917 40.2627 57.8191 40.0698 57.8191 39.3979C57.8274 30.1567 57.8274 20.9155 57.8191 11.6743C57.8191 10.5814 57.8503 10.5689 58.9697 10.5648C60.0308 10.5648 61.094 10.6145 62.1509 10.544C63.004 10.488 63.5824 10.8302 64.0942 11.4482C65.7796 13.4869 67.4794 15.511 69.1772 17.5392C71.7489 20.6148 74.3143 23.6945 76.9109 26.7472C77.1747 27.0158 77.5327 27.1719 77.9096 27.1828C79.9132 27.1931 81.9169 27.162 83.9205 27.0998C84.7132 27.0749 85.0253 27.3632 84.9983 28.1616C84.9567 29.4682 84.9796 30.7789 84.9983 32.0875C84.9983 32.691 84.7902 32.9668 84.1203 32.9626C80.9993 32.9419 77.8638 32.9295 74.7387 32.9875C73.7171 33.0083 73.1907 32.3197 72.6935 31.7225C70.2737 28.819 67.9372 25.8347 65.5569 22.8919C65.2616 22.5675 64.9454 22.2627 64.6102 21.9794L64.3855 22.108Z" fill="white"/>
        <path d="M19.0156 27.1067C21.4042 27.1067 23.5701 27.0818 25.734 27.1337C25.9235 27.1553 26.106 27.2181 26.2685 27.3177C26.431 27.4173 26.5696 27.5513 26.6744 27.7102C27.6044 29.388 28.4575 31.1052 29.348 32.8057C29.5706 33.233 29.8328 33.6353 30.149 34.1786C31.4099 32.6875 32.6021 31.2752 33.7985 29.8608C35.2133 28.1907 36.6309 26.526 38.0513 24.8669C39.5972 23.0564 41.1389 21.2418 42.6973 19.4417C43.9665 17.9734 45.271 16.5383 46.5381 15.0637C47.5285 13.9107 48.5147 12.7534 49.451 11.5506C50.535 10.1466 52.0934 10.0989 53.1441 11.5195C53.606 12.1416 54.0221 12.8032 54.4341 13.4606C54.9584 14.2902 54.8502 15.0886 54.226 15.8311C50.9345 19.6788 47.645 23.5265 44.3576 27.3743C42.2125 29.8919 40.084 32.4221 37.9431 34.9398C36.5449 36.5823 35.1425 38.2206 33.7173 39.8424C33.5238 40.0634 33.256 40.2065 32.9641 40.2447C30.8544 40.3401 28.7425 40.4355 26.6328 40.4189C26.3844 40.3915 26.1453 40.3091 25.9329 40.1777C25.7206 40.0464 25.5403 39.8695 25.4052 39.6599C23.6513 36.3127 21.9722 32.9281 20.2702 29.5539L19.0156 27.1067Z" fill="white"/>
        <path d="M84.8465 22.745C82.9739 22.745 81.2179 22.7658 79.4681 22.716C79.2891 22.716 78.9833 22.3012 78.977 22.071C78.9292 20.6815 78.9604 19.2879 78.9479 17.8984C78.9125 13.7921 77.2834 10.4324 73.9315 8.06405C72.2233 6.85084 70.228 6.07313 68.0642 6.05239C60.6759 5.98188 53.2917 5.94248 45.8972 5.8927C44.7008 5.8927 44.6072 5.79731 44.6051 4.6152C44.603 3.43309 44.6051 2.28001 44.6051 1.11034C44.5947 0.380339 44.9443 0.0443714 45.66 0.0443714C53.0296 0.0443714 60.4012 -0.0821359 67.7645 0.0920697C72.0798 0.19369 75.906 1.84657 79.0665 4.90139C81.6069 7.35064 83.4795 10.1607 84.4033 13.5951C84.9651 15.6959 84.784 17.8258 84.8444 19.9494C84.8673 20.8371 84.8465 21.7247 84.8465 22.745Z" fill="white"/>
        <path d="M5.71188 22.585C3.9267 22.585 2.25388 22.6016 0.583137 22.5601C0.456542 22.5256 0.340852 22.4594 0.246948 22.368C0.153043 22.2766 0.0840088 22.1629 0.0463357 22.0375C0.112916 18.5721 -0.369789 15.0693 1.0055 11.7055C2.07707 9.09459 3.6787 6.73277 5.70979 4.76836C8.27312 2.25274 11.3712 0.888133 14.8312 0.232788C15.6816 0.0926678 16.5433 0.0322625 17.405 0.0523616C24.4125 0.0385357 31.4208 0.0385357 38.4297 0.0523616C39.6614 0.0523616 40.0547 0.436028 40.038 1.69487C40.0255 2.8044 39.9215 3.91599 39.9506 5.02344C39.9714 5.86751 39.5761 6.06038 38.8354 6.06038C31.4346 6.04794 24.0359 6.07905 16.6372 6.03964C14.1176 6.02513 12.0078 7.02059 10.1082 8.49096C7.68639 10.3575 6.26116 12.9104 5.77013 15.9113C5.58288 17.0519 5.71812 18.2444 5.71188 19.412C5.70979 20.4282 5.71188 21.4527 5.71188 22.585Z" fill="white"/>
        <path d="M56.5728 50.979H46.4443C44.9983 50.979 44.7798 50.7488 44.7798 49.2951C44.7798 48.3756 44.7798 47.4576 44.7798 46.541C44.7798 45.3464 44.8235 45.2966 45.9949 45.2966C52.3865 45.2966 58.7782 45.3381 65.1678 45.2551C67.0758 45.2303 69.0107 45.3028 70.8958 44.6682C73.8794 43.6624 76.0578 41.7461 77.3977 38.9858C78.051 37.6378 78.8375 37.1193 80.2856 37.2832C81.326 37.3972 82.4037 37.2977 83.4648 37.2977C84.2971 37.2977 84.3866 37.4408 84.1577 38.2081C83.3442 40.9436 81.7983 43.1854 79.8321 45.2468C77.9017 47.3093 75.4986 48.8748 72.8287 49.8094C71.3972 50.3071 69.8617 50.7177 68.3574 50.7696C64.4375 50.9002 60.5093 50.8131 56.5852 50.8131L56.5728 50.979Z" fill="white"/>
        <path d="M0.691406 37.4651C2.53068 37.4651 4.31169 37.4465 6.10103 37.4879C6.32574 37.4879 6.59622 37.7866 6.75851 38.0106C7.73224 39.3627 8.57697 40.8352 9.68178 42.0795C11.3047 43.8983 13.533 44.7154 15.8904 45.1219C16.8235 45.2593 17.7665 45.3189 18.7096 45.3002C25.2969 45.3085 31.8855 45.3085 38.4755 45.3002C39.7239 45.3002 40.0194 45.7586 39.828 47.0195C39.7092 47.9826 39.6848 48.955 39.7551 49.9229C39.7926 50.7525 39.3785 50.9889 38.6566 50.9868C37.1772 50.9868 35.6979 50.9868 34.2186 50.9868C28.7465 50.9868 23.2766 51.0158 17.8066 50.9868C13.6121 50.9578 9.93146 49.4812 6.68569 46.8681C3.95175 44.6677 2.18113 41.814 1.0035 38.5581C0.939001 38.3797 0.907791 38.191 0.855776 38.0085C0.80376 37.826 0.760067 37.687 0.691406 37.4651Z" fill="white"/>
        <path d="M16.088 20.1877C15.5949 20.9488 15.1267 21.5502 14.7959 22.2159C14.5005 22.8111 14.101 22.9439 13.481 22.9293C11.8352 22.8879 10.1873 22.9293 8.54156 22.9003C8.20034 22.9003 7.8612 22.722 7.39722 22.5892C8.26692 21.1147 9.01802 19.7916 9.81906 18.4975C11.2755 16.1581 12.759 13.8478 14.1884 11.5044C14.3545 11.1871 14.611 10.9259 14.9257 10.7535C15.2404 10.5812 15.5992 10.5055 15.9569 10.5358C18.412 10.5711 18.4079 10.5359 19.5377 12.7279C21.1002 15.7537 22.6773 18.7691 24.2399 21.7949C24.6955 22.6805 24.5436 22.9127 23.5408 22.9148C21.7827 22.9148 20.0245 22.9045 18.2664 22.9148C17.7983 22.9148 17.5423 22.8236 17.3405 22.3258C17.0763 21.6498 16.6081 21.0442 16.088 20.1877Z" fill="white"/>
        <path d="M8.06937 27.2658C10.3851 27.2658 12.7008 27.2907 15.0145 27.2513C15.6845 27.241 16.1276 27.4587 16.4272 28.029C17.1555 29.4227 17.8837 30.8184 18.614 32.2079C18.9115 32.7699 18.6972 32.9711 18.125 32.9358C17.3261 32.8881 16.5271 32.7886 15.7261 32.7865C10.8179 32.7699 5.9076 32.7616 0.999411 32.7865C0.231661 32.7865 -0.0409012 32.5895 0.00487255 31.8035C0.0652096 30.6097 0.0652096 29.4136 0.00487255 28.2198C-0.0304981 27.4795 0.250386 27.2513 0.955718 27.2575C3.32555 27.2824 5.69746 27.2679 8.06729 27.2679L8.06937 27.2658Z" fill="white"/>
        <path d="M45.0087 32.4011L48.8017 27.71C49.713 28.7822 50.5411 29.732 51.3442 30.7046C52.3325 31.8992 53.2688 33.1373 54.2841 34.307C54.9583 35.0826 54.7793 35.8437 54.3195 36.5509C53.5773 37.6997 52.773 38.8074 51.9102 39.8691C51.5523 40.3067 51.0197 40.2175 50.6347 39.7198C49.4093 38.1353 48.1713 36.5613 46.9479 34.9768C46.3133 34.1535 45.6974 33.3177 45.0087 32.4011Z" fill="white"/>
        <path d="M35.4567 21.9304C33.8026 19.8295 32.1443 17.7391 30.5048 15.632C29.8473 14.7879 29.8494 14.1284 30.459 13.2159C30.8273 12.6664 31.2018 12.1209 31.5909 11.5859C32.6811 10.0948 33.917 10.1155 35.0343 11.5568C36.3014 13.1911 37.6184 14.7859 38.95 16.3682C39.3953 16.8971 39.595 17.2745 39.0499 17.9153C38.3799 18.7076 37.9513 19.703 37.2835 20.4973C36.8195 21.051 36.1308 21.416 35.4567 21.9304Z" fill="white"/>
     </svg>
  );

  const handleSignIn = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        navigate('/dashboard');
        setShowLoginPopup(false);
      })
      .catch((error) => {
        console.error("Error signing in:", error.message);
      });
  };
  
  const handleGoogleSignIn = (event) => {
    event.preventDefault();
  
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
  
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        const firstName = user.displayName.split(" ")[0];
        const lastName = user.displayName.split(" ")[1] || "";
        const email = user.email;
        const phoneNumber = user.phoneNumber || "";
  
        const db = getFirestore();
  
        getDoc(doc(db, "transcriptions", user.uid))
          .then((docSnapshot) => {
            if (docSnapshot.exists()) {
              navigate("/dashboard");
              setShowLoginPopup(false);
            } else {
              const userData = {
                email: email,
                firstName: firstName,
                lastName: lastName,
                dateOfBirth: "", 
                accountType: "Professional", 
                Phone: phoneNumber,
                credits: 1500, 
              };
  
              setDoc(doc(db, "transcriptions", user.uid), userData)
                .then(() => {
                  const promptDateOfBirth = () => {
                    const dateOfBirth = prompt(
                      "Please enter your date of birth (YYYY-MM-DD) for compliance purposes:"
                    );
                    if (dateOfBirth) {
                      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
                      if (!dateRegex.test(dateOfBirth)) {
                        alert("Invalid date of birth format. Please enter a valid date in the format YYYY-MM-DD.");
                        promptDateOfBirth();
                      } else {
                        updateDoc(doc(db, "transcriptions", user.uid), {
                          dateOfBirth: dateOfBirth,
                        })
                          .then(() => {
                            console.log("DOB success.");
                            promptPhoneNumber();
                          })
                          .catch((error) => {
                            console.error("Error adding date of birth:", error.message);
                            promptDateOfBirth();
                          });
                      }
                    } else {
                      alert("Date of birth is required. Please provide a valid date of birth.");
                      promptDateOfBirth();
                    }
                  };
  
                  const promptPhoneNumber = () => {
                    const phoneNumber = prompt(
                      "Please enter your phone number for compliance purposes:"
                    );
  
                    if (phoneNumber) {
                      const phoneRegex = /^\+?[1-9]\d{1,14}$/;
                      if (!phoneRegex.test(phoneNumber)) {
                        alert("Invalid phone number format. Please enter a valid phone number.");
                        promptPhoneNumber();
                      } else {
                        updateDoc(doc(db, "transcriptions", user.uid), {
                          Phone: phoneNumber,
                        })
                          .then(() => {
                            console.log("Phone number success.");
                            navigate("/dashboard");
                            setShowLoginPopup(false);
                          })
                          .catch((error) => {
                            console.error("Error adding phone number:", error.message);
                            promptPhoneNumber();
                          });
                      }
                    } else {
                      alert("Phone number is required. Please provide a valid phone number.");
                      promptPhoneNumber();
                    }
                  };
  
                  promptDateOfBirth();
                })
                .catch((error) => {
                  console.error("Error saving user data:", error.message);
                  alert("Error saving user data. Please try again.");
                });
            }
          })
          .catch((error) => {
            console.error("Error checking user existence:", error.message);
            alert("Error checking user existence. Please try again.");
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.error("Error signing in:", errorMessage);
        alert(errorMessage);
      });
  };

  const handleSignUp = (event) => {
    event.preventDefault();

    const trimmedFirstName = firstName.trim();
    const trimmedLastName = lastName.trim();
    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();
    const trimmedConfirmPassword = confirmPassword.trim();
    const trimmedDateOfBirth = dateOfBirth.trim();
    const trimmedAccountType = accountType.trim();

    if (!trimmedFirstName || !trimmedLastName || !trimmedEmail || !trimmedPassword || !trimmedConfirmPassword || !trimmedDateOfBirth || trimmedAccountType === "Select" || !phone) {
      alert("Please fill in all the fields correctly.");
      return;
    }

    if (trimmedPassword !== trimmedConfirmPassword) {
      alert("Passwords don't match!");
      return;
    }

    const db = getFirestore();

    createUserWithEmailAndPassword(auth, trimmedEmail, trimmedPassword)
      .then((userCredential) => {
        const userData = {
          email: trimmedEmail,
          firstName: trimmedFirstName,
          lastName: trimmedLastName,
          dateOfBirth: trimmedDateOfBirth,
          accountType: trimmedAccountType,
          Phone: phone,
          credits: 1500
        };

        return setDoc(doc(db, "transcriptions", userCredential.user.uid), userData);
      })
      .then(() => {
        navigate('/dashboard');
        toggleSignupPopup(false);
      })
      .catch((error) => {
        console.error("Error registering:", error.message);
        alert(error.message);
      });
  };

  const handleForgotPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert('Password reset email sent! Please check your inbox.');
      })
      .catch((error) => {
        console.error("Error in password reset:", error.message);
        alert(error.message);
      });
  };

  return (
    <div className={`container position-relative ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
    {!isAuthenticated && (
      <nav className="navbar navbar-expand-lg mt-4 px-3 sticky-top container trans-navbar">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            {isDarkMode ? logoSvgDark : logoSvgLight}
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav justify-content-end mb-2 mb-lg-0 w-75 me-5 pe-4">
            <li className="nav-item me-2">
              <Link className="nav-link" to="/home">Home</Link>
            </li>
            <li className="nav-item me-2">
              <Link className="nav-link" to="/home/products">Products</Link>
            </li>
            <li className="nav-item me-2">
              <Link className="nav-link" to="/home/contact">Contact Us</Link>
            </li>
          </ul>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" id='modeToggle' aria-current="page"  onClick={toggleTheme}>
                  <img src="../../../../assets/icons/ld_mode.png" alt="User" style={{ width: `clamp(8px, 4vw, 20px)`, verticalAlign: 'middle' }} />
                </a>
              </li>
              <li className="nav-item">
              <button className="nav-link">
              </button>
              </li>
              <li className="nav-item">
                <div class="blue-nav-btn-wrapper">
                 <button className="nav-link blue-nav-btn p-0 d-flex justify-content-center align-items-center" onClick={toggleLoginPopup}>
                  Sign In
                </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )}

      {showLoginPopup && (
        <div className="nav-login-card border-0 py-4 card">
          <div className={`popup ${showLoginPopup ? 'show' : ''}`}>
            <form onSubmit={handleSignIn}>
              <div className="form-group">
                <label htmlFor="login-email" className='label'>Email</label>
                <input type="email" id="login-email" className='form-control border-input-1 mt-1' placeholder='name@mail.com' value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="form-group my-3">
                <label htmlFor="login-password" className='label'>Password</label>
                <input type="password" id="login-password" className='form-control mt-1' placeholder="**********" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <button className='login-btn form-control border-input-1' onClick={handleSignIn}>Sign In</button>
              <button className="login-btn form-control border-input-1" onClick={handleGoogleSignIn}>Sign In with Google</button>
              <p className="nav-link outline-nav-btn p-0 d-flex justify-content-center align-items-center w-100 mt-2" onClick={handleForgotPassword}>
                Forgot Password?
              </p>
              <p className="nav-link outline-nav-btn p-0 d-flex justify-content-center align-items-center w-100 mt-2" onClick={toggleSignupPopup}>
                Sign Up
              </p>
              <p className="nav-link outline-nav-btn p-0 d-flex justify-content-center align-items-center w-100 mt-2" onClick={() => setShowLoginPopup(false)}>
                Close
              </p>
            </form>
          </div>
        </div>
      )}

      {showSignUpPopup && (
        <div className="nav-signup-card border-0 py-4 card">
          <div className={`popup ${showSignUpPopup ? 'show' : ''}`}>
            <form id='loginpopup' onSubmit={handleSignUp}>
              <div className="row">
                <div className="form-group px-1 col-6">                
                  <label htmlFor="fname" className='label'>First Name</label>
                  <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} id="fname" className='form-control border-input-1 mt-1' placeholder='John' />
                </div>
                <div className="form-group px-1 col-6">
                  <label htmlFor="lanme" className='label'>Last Name</label>                
                  <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} id="lanme" className='form-control border-input-1 mt-1' placeholder='Doe' />
                </div>
                <div className="form-group px-1 col-12 mt-2">
                  <label htmlFor="email" className='label'>Email</label>                
                  <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} id="email" className='form-control border-input-1 mt-1' placeholder='name@mail.com' />
                </div>
                <div className="form-group px-1 col-12 mt-2">
                  <label htmlFor="password" className='label'>Password</label>                
                  <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} id="password" className='form-control mt-1' placeholder="**********" />
                </div>
                <div className="form-group px-1 col-12 mt-2">
                  <label htmlFor="cpassword" className='label'>Confirm Password</label>                
                  <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} id="cpassword" className='form-control mt-1' placeholder="**********" />
                </div>
                <div className="form-group px-1 col-6 mt-2">
                  <label htmlFor="date" className='label'>Birthday</label>
                  <input type="date" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} id="date" className='form-control border-input-1 mt-1' />
                </div>
                <div className="form-group px-1 col-12 mt-2">
                  <label htmlFor="phone" className='label'>Phone Number</label>
                  <PhoneInput
                    country={'us'}
                    value={phone}
                    onChange={setPhone}
                    inputProps={{
                      id: 'phone',
                      className: 'form-control border-input-1 mt-1',
                    }}
                  />
                </div>
                <div className="form-group px-1 col-6 mt-2">
                  <label htmlFor="atype" className='label'>Account Type</label>                
                  <select name="atype" className='form-control border-input-1 mt-1' value={accountType} onChange={(e) => setAccountType(e.target.value)} required>
                    <option>Select</option>
                    <option value="Educator">Educator</option>
                    <option value="Student">Student</option>
                    <option value="Professional">Professional</option>
                  </select>
                </div>
                <div className="form-group px-1 col-12 mt-4">                
                  <button className='login-btn form-control border-input-1' type="submit">Register</button>
                </div>
                <p className="nav-link outline-nav-btn p-0 d-flex justify-content-center align-items-center w-100 mt-2" onClick={() => toggleSignupPopup(false)}>
                  Close
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;